import {useMemo} from 'react';
import {useIntl} from 'react-intl';

export function useTranslation() {
  const intl = useIntl();

  return useMemo(
    () => ({
      //@menu
      menu: {
        clients: intl.formatMessage({
          id: 'clients.title',
          defaultMessage: 'Clients',
        }),
        application: intl.formatMessage({
          id: 'applications.title',
          defaultMessage: 'Applications',
        }),
        applicationProcessing: intl.formatMessage({
          id: 'applications.processing.title',
          defaultMessage: 'Process calls',
        }),
        reports: {
          sla: intl.formatMessage({
            id: 'reports.sla',
            defaultMessage: 'Reports SLA',
          }),
          advancedSla: intl.formatMessage({
            id: 'reports.advancedSla',
            defaultMessage: 'SLA Report (expanded)',
          }),
          title: intl.formatMessage({
            id: 'reports.title',
            defaultMessage: 'Reports',
          }),
          history: intl.formatMessage({
            id: 'reports.history.title',
            defaultMessage: 'Call History',
          }),
          historyAuto: intl.formatMessage({
            id: 'reports.historyAuto.title',
            defaultMessage: 'Report History by applications in automatic mode',
          }),
          appeals: intl.formatMessage({
            id: 'applications.processing.appeals',
            defaultMessage: 'Appeals ',
          }),
          mails: intl.formatMessage({
            id: 'reports.mails',
            defaultMessage: 'MailsList ',
          }),
          rating: intl.formatMessage({
            id: 'reports.rating.title',
            defaultMessage: 'Call Rating',
          }),
          ners: intl.formatMessage({
            id: 'nerc.title',
            defaultMessage: 'NERC Reports',
          }),
          nersSla: intl.formatMessage({
            id: 'nerc.sla.title',
            defaultMessage: 'SLA Report (NERC)',
          }),
          nersTopics: intl.formatMessage({
            id: 'nerc.topics.title',
            defaultMessage: 'Topics Report (NERC)',
          }),
          nercReportDownloadFormat: intl.formatMessage({
            id: 'reports.download.format',
            defaultMessage: 'Download the report in the format:',
          }),
          operatorWork: intl.formatMessage({
            id: 'reports.operatorWork.title',
            defaultMessage: 'Operators work Report ',
          }),
          protected: intl.formatMessage({
            id: 'protected.reports.title',
            defaultMessage: 'Protected reports',
          }),
        },
        setting: {
          title: intl.formatMessage({
            id: 'settings.title',
            defaultMessage: 'Settings',
          }),
          general: intl.formatMessage({
            id: 'settings.general.title',
            defaultMessage: 'General',
          }),
          generalSetting: intl.formatMessage({
            id: 'setting.generalSetting',
            defaultMessage: 'General setting',
          }),
          users: intl.formatMessage({
            id: 'settings.users.title',
            defaultMessage: 'Users',
          }),
          roles: intl.formatMessage({
            id: 'settings.roles.title',
            defaultMessage: 'Roles',
          }),
          sounds: intl.formatMessage({
            id: 'settings.sounds.title',
            defaultMessage: 'Sounds',
          }),
          topic: intl.formatMessage({
            id: 'settings.topics.title',
            defaultMessage: 'Topics',
          }),
          routes: intl.formatMessage({
            id: 'settings.routes.title',
            defaultMessage: 'Routes',
          }),
          rules: intl.formatMessage({
            id: 'settings.rules.title',
            defaultMessage: 'Rules',
          }),
          workingTime: intl.formatMessage({
            id: 'settings.workingTime.title',
            defaultMessage: 'Working time',
          }),
          sipTrunks: intl.formatMessage({
            id: 'sip-trunks.title',
            defaultMessage: 'SIP Trunks',
          }),
          blacklist: intl.formatMessage({
            id: 'blacklist.title',
            defaultMessage: 'Blacklist',
          }),
          mailSenderConfig: intl.formatMessage({
            id: 'mailSenderConfigs',
            defaultMessage: 'Mail sender config',
          }),
        },
        handbooks: {
          title: intl.formatMessage({
            id: 'handbook.title',
            defaultMessage: 'Handbooks',
          }),
          externalPhoneNumbers: intl.formatMessage({
            id: 'handbooks.externalPhoneNumbers.title',
            defaultMessage: 'External phone numbers',
          }),
        },
        dispatcherModule: {
          title: intl.formatMessage({
            id: 'dispatcherModule',
            defaultMessage: 'Dispatcher module',
          }),
        },
        other: intl.formatMessage({
          id: 'other.title',
          defaultMessage: 'Other',
        }),
        testing: intl.formatMessage({
          id: 'testing.title',
          defaultMessage: 'Testing',
        }),
        module: intl.formatMessage({
          id: 'module',
          defaultMessage: 'Module',
        }),

        demo: {
          title: intl.formatMessage({
            id: 'demo.title',
            defaultMessage: 'Demo',
          }),
          CRM: intl.formatMessage({
            id: 'demo.CRM',
            defaultMessage: 'CRM',
          }),
          CRMcanban: intl.formatMessage({
            id: 'demo.CRM.canban',
            defaultMessage: 'Canban',
          }),
          CRMAddStatus: intl.formatMessage({
            id: 'demo.CRM.add.status',
            defaultMessage: 'Canban',
          }),
          CRMcanbanNameProject: intl.formatMessage({
            id: 'demo.CRM.canban.project.name',
            defaultMessage: 'Project Name',
          }),
          CRMNameStatus: intl.formatMessage({
            id: 'demo.CRM.status.name',
            defaultMessage: 'Status Name',
          }),
          CRMcanbanProjectAddUser: intl.formatMessage({
            id: 'demo.CRM.canban.add.user',
            defaultMessage: 'Add User to Project',
          }),
          CRMcanbanPriority: intl.formatMessage({
            id: 'demo.CRM.canban.priority',
            defaultMessage: 'Priority',
          }),
          CRMcanbanPriorityLow: intl.formatMessage({
            id: 'demo.CRM.canban.priority.low',
            defaultMessage: 'Low',
          }),
          CRMcanbanPriorityMedium: intl.formatMessage({
            id: 'demo.CRM.canban.priority.medium',
            defaultMessage: 'Medium',
          }),
          CRMcanbanPriorityHigh: intl.formatMessage({
            id: 'demo.CRM.canban.priority.high',
            defaultMessage: 'High',
          }),
          CRMcanbanPriorityCritical: intl.formatMessage({
            id: 'demo.CRM.canban.priority.critical',
            defaultMessage: 'Critical',
          }),
          CRMcanbanTitle: intl.formatMessage({
            id: 'demo.CRM.canban.title',
            defaultMessage: 'Canban title',
          }),
          CRMList: intl.formatMessage({
            id: 'demo.CRM.list',
            defaultMessage: 'List',
          }),
          CRMListTitle: intl.formatMessage({
            id: 'demo.CRM.list.title',
            defaultMessage: 'List title',
          }),
          inputChanels: intl.formatMessage({
            id: 'demo.input.channels',
            defaultMessage: 'Input chanels',
          }),
          inputChanelsMessengers: intl.formatMessage({
            id: 'demo.input.chanels.messengers',
            defaultMessage: 'Chats (messengers)',
          }),
          inputChanelsMessengersTitle: intl.formatMessage({
            id: 'demo.input.channels.messengers.title',
            defaultMessage: 'Chats (messengers) title',
          }),
          inputChanelsVideoMessengers: intl.formatMessage({
            id: 'demo.input.chanels.video-messengers',
            defaultMessage: 'Video-chats',
          }),
          inputChanelsVideoMessengersTitle: intl.formatMessage({
            id: 'demo.input.chanels.video-messengers.title',
            defaultMessage: 'Video-chats title',
          }),
          inputChanelsSocialNetworcs: intl.formatMessage({
            id: 'demo.input.chanels.social.networks',
            defaultMessage: 'Social networks',
          }),
          inputChanelsSocialNetworcsTitle: intl.formatMessage({
            id: 'demo.input.chanels.social.networks.title',
            defaultMessage: 'Social networks title',
          }),
          inputChanelsCRM: intl.formatMessage({
            id: 'demo.input.chanels.CRM',
            defaultMessage: 'CRM',
          }),
          inputChanelsCRMTitle: intl.formatMessage({
            id: 'demo.input.chanels.CRM.title',
            defaultMessage: 'CRM title',
          }),
          inputChanelsMarketplace: intl.formatMessage({
            id: 'demo.input.chanels.marketplace',
            defaultMessage: 'Marketplace',
          }),
          inputChanelsMarketplaceTitle: intl.formatMessage({
            id: 'demo.input.chanels.marketplace.title',
            defaultMessage: 'Marketplace title',
          }),
          inputChanelsWebChats: intl.formatMessage({
            id: 'demo.input.chanels.web-chats',
            defaultMessage: 'web-chats',
          }),
          inputChanelsEmail: intl.formatMessage({
            id: 'demo.input.chanels.email',
            defaultMessage: 'Email',
          }),
          inputChanelsForms: intl.formatMessage({
            id: 'demo.input.chanels.forms',
            defaultMessage: 'Forms',
          }),
          inputChanelsFormsTitle: intl.formatMessage({
            id: 'demo.input.chanels.forms.title',
            defaultMessage: 'Forms title',
          }),
          inputChanelsCRMSiteBilder: intl.formatMessage({
            id: 'demo.input.chanels.CRM.site-bilder',
            defaultMessage: 'CRM site-bilder',
          }),
          outputChanels: intl.formatMessage({
            id: 'demo.output.chanels',
            defaultMessage: 'Output chanels',
          }),
          outputChanelsSms: intl.formatMessage({
            id: 'demo.output.chanels.sms',
            defaultMessage: 'SMS',
          }),
          outputChanelsSmsTitle: intl.formatMessage({
            id: 'demo.output.chanels.sms.title',
            defaultMessage: 'SMS title',
          }),
          outputChanelsMessengers: intl.formatMessage({
            id: 'demo.output.chanels.messengers',
            defaultMessage: 'Messengers',
          }),
          outputChanelsMessengersTitle: intl.formatMessage({
            id: 'demo.output.chanels.messengers.title',
            defaultMessage: 'Messengers title',
          }),
          outputChanelsCallback: intl.formatMessage({
            id: 'demo.output.chanels.callback',
            defaultMessage: 'Callbakc',
          }),
          outputChanelsEmail: intl.formatMessage({
            id: 'demo.output.chanels.email',
            defaultMessage: 'Email',
          }),
          externalTools: intl.formatMessage({
            id: 'demo.external.tools',
            defaultMessage: 'External Tools',
          }),
          externalToolsDB: intl.formatMessage({
            id: 'demo.external.tools.data.base',
            defaultMessage: 'Data base',
          }),
          externalToolsNPSAgents: intl.formatMessage({
            id: 'demo.external.tools.NPS-agents',
            defaultMessage: 'NPS-agents',
          }),
          externalToolsNPSAgentsTitle: intl.formatMessage({
            id: 'demo.external.tools.NPS-agents.title',
            defaultMessage: 'NPS-agents title',
          }),
          externalToolsVirtualAgents: intl.formatMessage({
            id: 'demo.external.tools.virtual-agents',
            defaultMessage: 'Virtual-agents',
          }),
          externalToolsVirtualAgentsTitle: intl.formatMessage({
            id: 'demo.external.tools.virtual-agents.title',
            defaultMessage: 'Virtual-agents title',
          }),
          externalToolsSuperAgents: intl.formatMessage({
            id: 'demo.external.tools.super-agents',
            defaultMessage: 'Super agents',
          }),
          externalToolsSuperAgentsTitle: intl.formatMessage({
            id: 'demo.external.tools.super-agents.title',
            defaultMessage: 'Super agents title',
          }),
          externalToolsAdveristingOffices: intl.formatMessage({
            id: 'demo.external.tools.adveristing.offices',
            defaultMessage: 'Adveristing offices',
          }),
          externalToolsAI: intl.formatMessage({
            id: 'demo.external.tools.AI',
            defaultMessage: 'Artificial intelligence',
          }),
          internalTools: intl.formatMessage({
            id: 'demo.internal.tools',
            defaultMessage: 'Internal tools',
          }),
          internalToolsTimeTracking: intl.formatMessage({
            id: 'demo.internal.tools.time.tracking',
            defaultMessage: 'Time tracking',
          }),
          internalToolsDataBase: intl.formatMessage({
            id: 'demo.internal.tools.data.base',
            defaultMessage: 'Data base',
          }),
          settings: intl.formatMessage({
            id: 'demo.settings',
            defaultMessage: 'Settings',
          }),
          settingsInterface: intl.formatMessage({
            id: 'demo.settings.interface',
            defaultMessage: 'Settings interface',
          }),
          settingsInterfaceTitle: intl.formatMessage({
            id: 'read:demo:settings:interface.title',
            defaultMessage: 'Settings interface title',
          }),
          management: intl.formatMessage({
            id: 'project.and.task.management',
            defaultMessage: 'Project and Task Management',
          }),
          financesReport: intl.formatMessage({
            id: 'demo.finances.report',
            defaultMessage: 'Finances report',
          }),
          agreementsTitle: intl.formatMessage({
            id: 'agreements.title',
            defaultMessage: 'Sales funnel',
          }),
          clients: intl.formatMessage({
            id: 'crm.clients',
            defaultMessage: 'Clients',
          }),
          agreements: intl.formatMessage({
            id: 'crm.agreements',
            defaultMessage: 'Agreements',
          }),
          agreementsCreate: intl.formatMessage({
            id: 'crm.agreements.create',
            defaultMessage: 'Agreement create',
          }),
          agreementAddCall: intl.formatMessage({
            id: 'demo.agreement.add.call',
            defaultMessage: 'Add call',
          }),
          agreementGoToCall: intl.formatMessage({
            id: 'demo.agreement.go.to.call',
            defaultMessage: 'Go to call',
          }),
          agreementAddTask: intl.formatMessage({
            id: 'demo.agreement.add.task',
            defaultMessage: 'Add task',
          }),
          agreementGoToTask: intl.formatMessage({
            id: 'demo.agreement.go.to.task',
            defaultMessage: 'Go to task',
          }),
          taskForm: {
            create: intl.formatMessage({
              id: 'task.create',
              defaultMessage: 'Task create',
            }),
            title: intl.formatMessage({
              id: 'task.form.title',
              defaultMessage: 'Task form',
            }),
            name: intl.formatMessage({
              id: 'task.name',
              defaultMessage: 'Task name',
            }),
            start: intl.formatMessage({
              id: 'task.date.start',
              defaultMessage: 'Start',
            }),
            end: intl.formatMessage({
              id: 'task.date.end',
              defaultMessage: 'End',
            }),
            project: intl.formatMessage({
              id: 'task.project',
              defaultMessage: 'Task project',
            }),
            performer: intl.formatMessage({
              id: 'task.user',
              defaultMessage: ' Task performer',
            }),
            status: intl.formatMessage({
              id: 'task.status',
              defaultMessage: 'Task status',
            }),
            priority: intl.formatMessage({
              id: 'task.priority',
              defaultMessage: 'Task priority',
            }),
            description: intl.formatMessage({
              id: 'task.description',
              defaultMessage: 'Description',
            }),
            submit: intl.formatMessage({
              id: 'task.submit',
              defaultMessage: 'Submit',
            }),
            cancel: intl.formatMessage({
              id: 'task.cancel',
              defaultMessage: 'Cancel',
            }),
            comments: intl.formatMessage({
              id: 'task.comments',
              defaultMessage: 'Task Comments',
            }),
            addComments: intl.formatMessage({
              id: 'task.comments.add',
              defaultMessage: 'Add comments',
            }),
          },
          project: {
            info: intl.formatMessage({
              id: 'project.info',
              defaultMessage: 'Project Info',
            }),
            users: intl.formatMessage({
              id: 'project.users',
              defaultMessage: 'Project Users',
            }),
          },
          file: {
            additional: intl.formatMessage({
              id: 'file.additional',
              defaultMessage: 'Additional',
            }),
            add: intl.formatMessage({
              id: 'file.add',
              defaultMessage: 'Choose File',
            }),
            added: intl.formatMessage({
              id: 'file.added',
              defaultMessage: 'File added',
            }),
            deleted: intl.formatMessage({
              id: 'file.deleted',
              defaultMessage: 'File is deleted',
            }),
          },
          tasksReport: {
            title: intl.formatMessage({
              id: 'task.report',
              defaultMessage: 'Tasks Report',
            }),
            tasksName: intl.formatMessage({
              id: 'task.name',
              defaultMessage: 'Task name',
            }),
            tasksStart: intl.formatMessage({
              id: 'task.date.start',
              defaultMessage: 'Start',
            }),
            tasksEnd: intl.formatMessage({
              id: 'task.date.end',
              defaultMessage: 'End',
            }),
            tasksProject: intl.formatMessage({
              id: 'task.project',
              defaultMessage: 'Task project',
            }),
            tasksPerformer: intl.formatMessage({
              id: 'task.user',
              defaultMessage: ' Task performer',
            }),
            tasksStatus: intl.formatMessage({
              id: 'task.status',
              defaultMessage: 'Task status',
            }),
            tasksPriority: intl.formatMessage({
              id: 'task.priority',
              defaultMessage: 'Task priority',
            }),
          },
          consumers: {
            consumerId: intl.formatMessage({
              id: 'demo.consumer.consumerId',
              defaultMessage: 'Consumer ID',
            }),
            fullName: intl.formatMessage({
              id: 'demo.consumer.fullName',
              defaultMessage: 'Full name',
            }),
            shortName: intl.formatMessage({
              id: 'demo.consumer.shortName',
              defaultMessage: 'Short name',
            }),
            edrpou: intl.formatMessage({
              id: 'demo.consumer.edrpou',
              defaultMessage: 'ERDPO',
            }),
            ipnPdv: intl.formatMessage({
              id: 'demo.consumer.ipnPdv',
              defaultMessage: 'IPN PDV',
            }),
            legalAddress: intl.formatMessage({
              id: 'demo.consumer.legalAddress',
              defaultMessage: 'Legal Address',
            }),
            actualAddress: intl.formatMessage({
              id: 'demo.consumer.actualAddress',
              defaultMessage: 'Actual address',
            }),
            email: intl.formatMessage({
              id: 'demo.consumer.email',
              defaultMessage: 'Email',
            }),
            phone: intl.formatMessage({
              id: 'demo.consumer.phone',
              defaultMessage: 'Phone number',
            }),
            bankAccounts: intl.formatMessage({
              id: 'demo.consumer.bankAccounts',
              defaultMessage: 'Bank Accounts',
            }),
            contractDetails: intl.formatMessage({
              id: 'demo.consumer.contractDetails',
              defaultMessage: 'Contract details',
            }),
            taxationForm: intl.formatMessage({
              id: 'demo.consumer.taxationForm',
              defaultMessage: 'Taxation form',
            }),
            directorSigner: intl.formatMessage({
              id: 'demo.consumer.directorSigner',
              defaultMessage: 'Director',
            }),
            tkoCount: intl.formatMessage({
              id: 'demo.consumer.tkoCount',
              defaultMessage: 'Tko count',
            }),
            beneficiaryOwner: intl.formatMessage({
              id: 'demo.consumer.beneficiaryOwner',
              defaultMessage: 'Beneficiary Owner',
            }),
            osrRegion: intl.formatMessage({
              id: 'demo.consumer.osrRegion',
              defaultMessage: 'Region',
            }),
            responsibleManager: intl.formatMessage({
              id: 'demo.consumer.responsibleManager',
              defaultMessage: 'Manager',
            }),
            consumptionForm: intl.formatMessage({
              id: 'demo.consumer.consumptionForm',
              defaultMessage: 'Consumption form',
            }),
            consumptionGroup: intl.formatMessage({
              id: 'demo.consumer.consumptionGroup',
              defaultMessage: 'Consumption group',
            }),
            eicEcrbCode: intl.formatMessage({
              id: 'demo.consumer.eicEcrbCode',
              defaultMessage: 'EIC code',
            }),
            activityType: intl.formatMessage({
              id: 'demo.consumer.activityType',
              defaultMessage: 'Activity Type',
            }),
            energyType: intl.formatMessage({
              id: 'demo.consumer.energyType',
              defaultMessage: 'Energy Type',
            }),
            consumerType: intl.formatMessage({
              id: 'demo.consumer.consumerType',
              defaultMessage: 'Consumer type',
            }),
            addedBy: intl.formatMessage({
              id: 'demo.consumer.addedBy',
              defaultMessage: 'Creator',
            }),
            createdAt: intl.formatMessage({
              id: 'demo.consumer.createdAt',
              defaultMessage: 'CreatedAt',
            }),
            updateAt: intl.formatMessage({
              id: 'demo.consumer.updateAt',
              defaultMessage: 'UpdatedAt',
            }),
            consumerStatus: intl.formatMessage({
              id: 'demo.consumer.consumerStatus',
              defaultMessage: 'Consumer status',
            }),
            closeConsumer: intl.formatMessage({
              id: 'demo.consumer.close',
              defaultMessage: 'Close consumer',
            }),
          },
          scopes: {
            consumptionDifference: intl.formatMessage({
              id: 'demo.scopes.consumptionDifference',
              defaultMessage: 'Consumption difference',
            }),
            actualConsumption: intl.formatMessage({
              id: 'demo.scopes.actualConsumption',
              defaultMessage: 'Actual consumption',
            }),
            correctedRequest: intl.formatMessage({
              id: 'demo.scopes.correctedRequest',
              defaultMessage: 'Corrected request',
            }),
            correction: intl.formatMessage({
              id: 'demo.scopes.correction',
              defaultMessage: 'Correction',
            }),
            tkoCount: intl.formatMessage({
              id: 'demo.scopes.tkoCount',
              defaultMessage: 'Count TKO',
            }),
            requestFromConsumer: intl.formatMessage({
              id: 'demo.scopes.requestFromConsumer',
              defaultMessage: 'Request from consumer',
            }),
            changer: intl.formatMessage({
              id: 'demo.scopes.changer',
              defaultMessage: 'Request from consumer',
            }),
            timeChange: intl.formatMessage({
              id: 'demo.scopes.timeChange',
              defaultMessage: 'Request from consumer',
            }),
            toAdjust: intl.formatMessage({
              id: 'demo.scopes.to.adjust',
              defaultMessage: ' To adjust',
            }),
            closeScope: intl.formatMessage({
              id: 'demo.scopes.to.close',
              defaultMessage: 'Close scope',
            }),
            cancelAdjust: intl.formatMessage({
              id: 'demo.scopes.cancel.adjust',
              defaultMessage: 'Close scope',
            }),
            sellingPrice: intl.formatMessage({
              id: 'demo.scopes.selling.price',
              defaultMessage: 'Selling price',
            }),
            purchasePrice: intl.formatMessage({
              id: 'demo.scopes.purchase.price',
              defaultMessage: 'Purchase price',
            }),
          },
          payments: {
            amount: intl.formatMessage({
              id: 'demo.payments.amount',
              defaultMessage: 'Amount',
            }),
            paymentDate: intl.formatMessage({
              id: 'demo.payments.paymentDate',
              defaultMessage: 'Payment Date',
            }),
            paymentMethod: intl.formatMessage({
              id: 'demo.payments.paymentMethod',
              defaultMessage: 'Payment method',
            }),
            description: intl.formatMessage({
              id: 'demo.payments.description',
              defaultMessage: 'Description',
            }),
            recipient: intl.formatMessage({
              id: 'demo.payments.recipient',
              defaultMessage: 'Recipient',
            }),
            close: intl.formatMessage({
              id: 'demo.payment.close',
              defaultMessage: 'To close payment',
            }),
          },
          agreementForm: {
            price: intl.formatMessage({
              id: 'demo.scopes.pricing',
              defaultMessage: 'Price',
            }),
            name: intl.formatMessage({
              id: 'agreement.name',
              defaultMessage: 'Agreement name',
            }),
            start: intl.formatMessage({
              id: 'agreement.date.start',
              defaultMessage: 'Start',
            }),
            end: intl.formatMessage({
              id: 'agreement.date.end',
              defaultMessage: 'End',
            }),
            sales: intl.formatMessage({
              id: 'agreement.sales',
              defaultMessage: 'Agreement sales',
            }),
            performer: intl.formatMessage({
              id: 'agreement.user',
              defaultMessage: 'Agreement performer',
            }),
            stage: intl.formatMessage({
              id: 'agreement.stage',
              defaultMessage: 'Agreement stage',
            }),
            priority: intl.formatMessage({
              id: 'agreement.priority',
              defaultMessage: 'agreement priority',
            }),
            description: intl.formatMessage({
              id: 'agreement.description',
              defaultMessage: 'Description',
            }),
            notes: intl.formatMessage({
              id: 'agreement.notes',
              defaultMessage: 'Agreement notes',
            }),
            addNotes: intl.formatMessage({
              id: 'agreement.notes.add',
              defaultMessage: 'Add notes',
            }),
            tabs: {
              parameter: intl.formatMessage({
                id: 'agreement.tabs.parameter',
                defaultMessage: 'Parameters',
              }),
              payment: intl.formatMessage({
                id: 'agreement.tabs.payment',
                defaultMessage: 'Payments',
              }),
              scope: intl.formatMessage({
                id: 'agreement.tabs.scope',
                defaultMessage: 'Scopes',
              }),
              application: intl.formatMessage({
                id: 'agreement.tabs.application',
                defaultMessage: 'Applications',
              }),
              task: intl.formatMessage({
                id: 'agreement.tabs.task',
                defaultMessage: 'Tasks',
              }),
              general: intl.formatMessage({
                id: 'agreement.tabs.general',
                defaultMessage: 'General',
              }),
            },
          },
          parameters: {
            distributionPayment: intl.formatMessage({
              id: 'agreement.parameters.distributionPayment',
              defaultMessage: 'Payment/Distribution',
            }),
            tkoCount: intl.formatMessage({
              id: 'agreement.parameters.tkoCount',
              defaultMessage: 'TKO Count',
            }),
            ab: intl.formatMessage({
              id: 'agreement.parameters.ab',
              defaultMessage: 'A/B',
            }),
            osp: intl.formatMessage({
              id: 'agreement.parameters.osp',
              defaultMessage: 'OSR',
            }),
            transfer: intl.formatMessage({
              id: 'agreement.parameters.transfer',
              defaultMessage: 'Transfer',
            }),
            price: intl.formatMessage({
              id: 'finance.report.priceElectricity',
              defaultMessage: 'Price Electricity',
            }),
            include: intl.formatMessage({
              id: 'agreement.parameters.include.distribution',
              defaultMessage: 'Include distribution',
            }),
            close: intl.formatMessage({
              id: 'agreement.parameters.close',
              defaultMessage: 'To close payment',
            }),
          },
          agreementReport: {
            agreementPricing: intl.formatMessage({
              id: 'demo.scopes.pricing',
              defaultMessage: 'Pricing',
            }),
            agreementProfit: intl.formatMessage({
              id: 'demo.scopes.profit',
              defaultMessage: 'Profit',
            }),
            agreementStart: intl.formatMessage({
              id: 'agreement.date.start',
              defaultMessage: 'Start',
            }),
            agreementEnd: intl.formatMessage({
              id: 'agreement.date.end',
              defaultMessage: 'End',
            }),
            agreementStage: intl.formatMessage({
              id: 'agreement.stage',
              defaultMessage: 'Stage',
            }),
            distributionPayment: intl.formatMessage({
              id: 'agreement.parameters.distributionPayment',
              defaultMessage: 'Payment/Distribution',
            }),
            transfer: intl.formatMessage({
              id: 'agreement.parameters.transfer',
              defaultMessage: 'Transfer',
            }),
            agreementName: intl.formatMessage({
              id: 'agreement.name',
              defaultMessage: 'Name organization',
            }),
            agreementPriority: intl.formatMessage({
              id: 'agreement.priority',
              defaultMessage: 'Priority',
            }),
            tkoCount: intl.formatMessage({
              id: 'agreement.parameters.tkoCount',
              defaultMessage: 'TKO Count',
            }),
            ab: intl.formatMessage({
              id: 'agreement.parameters.ab',
              defaultMessage: 'A/B',
            }),
            osp: intl.formatMessage({
              id: 'agreement.parameters.osp',
              defaultMessage: 'OSR',
            }),
            typeActivity: intl.formatMessage({
              id: 'finance.report.typeActivity',
              defaultMessage: 'Type of Activity',
            }),
            correctedRequest: intl.formatMessage({
              id: 'demo.scopes.correctedRequest',
              defaultMessage: 'Corrected Request',
            }),
            consumptionDifference: intl.formatMessage({
              id: 'demo.scopes.consumptionDifference',
              defaultMessage: 'Consumption Difference',
            }),
            actualConsumption: intl.formatMessage({
              id: 'demo.scopes.actualConsumption',
              defaultMessage: 'Actual Consumption',
            }),
            pricing: intl.formatMessage({
              id: 'finance.report.pricing',
              defaultMessage: 'Pricing',
            }),
            priceElectricity: intl.formatMessage({
              id: 'finance.report.priceElectricity',
              defaultMessage: 'Electricity Price',
            }),
            plannedVolume: intl.formatMessage({
              id: 'finance.report.plannedVolume',
              defaultMessage: 'Planned Supply Volume',
            }),
            actualVolume: intl.formatMessage({
              id: 'finance.report.actualVolume',
              defaultMessage: 'Actual Supply Volume',
            }),
            correctionFirst: intl.formatMessage({
              id: 'finance.report.correctionFirst',
              defaultMessage: 'Adjustment (1st Decade)',
            }),
            correctionSecond: intl.formatMessage({
              id: 'finance.report.correctionSecond',
              defaultMessage: 'Adjustment (2st Decade)',
            }),
            correctionThird: intl.formatMessage({
              id: 'finance.report.correctionThird',
              defaultMessage: 'Adjustment (3st Decade)',
            }),
            deliveryCost: intl.formatMessage({
              id: 'finance.report.deliveryCost',
              defaultMessage: 'Delivery Cost',
            }),
            paidElectricityConsumed: intl.formatMessage({
              id: 'finance.report.paidElectricityConsumed',
              defaultMessage: 'Paid for Consumed Electricity',
            }),
            accountsReceivable: intl.formatMessage({
              id: 'finance.report.accountsReceivable',
              defaultMessage: 'Accounts Receivable',
            }),
          },
        },
      },

      //@blacklist
      blacklist: {
        title: intl.formatMessage({
          id: 'blacklist.title',
          defaultMessage: 'Blacklist',
        }),
        addedBy: intl.formatMessage({
          id: 'blacklist.added.by',
          defaultMessage: 'Added',
        }),
        createdAt: intl.formatMessage({
          id: 'blacklist.createdAt',
          defaultMessage: 'Date',
        }),
        add: intl.formatMessage({
          id: 'blacklist.add.title',
          defaultMessage: 'Add in blacklist',
        }),
        error: intl.formatMessage({
          id: 'blacklist.error',
          defaultMessage: 'This number has already been blacklisted',
        }),
        create: intl.formatMessage({
          id: 'blacklist.create',
          defaultMessage: 'Add a number to blacklist',
        }),
        edit: intl.formatMessage({
          id: 'blacklist.edit',
          defaultMessage: 'Edit',
        }),
      },
      //@action
      action: {
        create: intl.formatMessage({
          id: 'action.create',
          defaultMessage: 'Create',
        }),
        edit: intl.formatMessage({
          id: 'actions.edit',
          defaultMessage: 'Edit',
        }),
        copy: intl.formatMessage({
          id: 'action.copy',
          defaultMessage: 'Copy',
        }),
        dispatcherCall: intl.formatMessage({
          id: 'action.dispatcherCall',
          defaultMessage: 'Dispatcher call',
        }),
        showAll: intl.formatMessage({
          id: 'show.all',
          defaultMessage: 'Show all',
        }),
        hideAll: intl.formatMessage({
          id: 'hide.all',
          defaultMessage: 'Hide all',
        }),
        deleteRoute: intl.formatMessage({
          id: 'action.deleteRoute',
          defaultMessage: 'Delete route',
        }),
        endCall: intl.formatMessage({
          id: 'action.endCall',
          defaultMessage: 'End call',
        }),
        holdCall: intl.formatMessage({
          id: 'action.holdCall',
          defaultMessage: 'Hold',
        }),
        connecting: intl.formatMessage({
          id: 'action.connecting',
          defaultMessage: 'Connecting...',
        }),
        accept: intl.formatMessage({
          id: 'action.accept',
          defaultMessage: 'Accept',
        }),
        resetPasswordLogin: intl.formatMessage({
          id: 'resetPassword.logIn',
          defaultMessage: 'Login',
        }),
        clear: intl.formatMessage({
          id: 'action.clear',
          defaultMessage: 'Clear',
        }),
        createExternalNumber: intl.formatMessage({
          id: 'sip-trunks.create.title',
          defaultMessage: 'Create external number',
        }),
        editExternalNumber: intl.formatMessage({
          id: 'sip-trunks.edit.title',
          defaultMessage: 'Edit external number',
        }),
        createNewRole: intl.formatMessage({
          id: 'action.newRole',
          defaultMessage: 'Create new role',
        }),
        resendInviteLink: intl.formatMessage({
          id: 'users.action.sendInviteLink',
          defaultMessage: 'Resent invite link',
        }),
        actions: intl.formatMessage({
          id: 'actions',
          defaultMessage: 'Actions',
        }),
        save: intl.formatMessage({
          id: 'action.save',
          defaultMessage: 'Save',
        }),
        ok: intl.formatMessage({
          id: 'action.ok',
          defaultMessage: 'Ok',
        }),
        yes: intl.formatMessage({
          id: 'action.yes',
          defaultMessage: 'yes',
        }),
        no: intl.formatMessage({
          id: 'action.no',
          defaultMessage: 'no',
        }),
        add: intl.formatMessage({
          id: 'action.add',
          defaultMessage: 'Add',
        }),
        logout: intl.formatMessage({
          id: 'action.logout',
          defaultMessage: 'Logout',
        }),
        connect: intl.formatMessage({
          id: 'action.connect',
          defaultMessage: 'Connect',
        }),
        call: intl.formatMessage({
          id: 'action.call',
          defaultMessage: 'Call',
        }),
        hold: intl.formatMessage({
          id: 'action.hold',
          defaultMessage: 'Hold',
        }),
        unhold: intl.formatMessage({
          id: 'action.unhold',
          defaultMessage: 'Unhold',
        }),
        hangup: intl.formatMessage({
          id: 'action.hangup',
          defaultMessage: 'Hangup',
        }),
        mute: intl.formatMessage({
          id: 'action.mute',
          defaultMessage: 'Mute',
        }),
        unmute: intl.formatMessage({
          id: 'action.unmute',
          defaultMessage: 'Unmute',
        }),
        spotPostPrecessing: intl.formatMessage({
          id: 'action.stopPostProcessing',
          defaultMessage: 'Stop post precessing',
        }),
        disconnect: intl.formatMessage({
          id: 'action.disconnect',
          defaultMessage: 'Disconnect',
        }),
        answer: intl.formatMessage({
          id: 'action.answer',
          defaultMessage: 'Answer',
        }),
        reject: intl.formatMessage({
          id: 'action.reject',
          defaultMessage: 'Reject',
        }),
        back: intl.formatMessage({
          id: 'action.back',
          defaultMessage: 'Back',
        }),
        delete: intl.formatMessage({
          id: 'action.delete',
          defaultMessage: 'Delete',
        }),
        postpone: intl.formatMessage({
          id: 'action.postpone',
          defaultMessage: 'postpone',
        }),
        finish: intl.formatMessage({
          id: 'action.finish',
          defaultMessage: 'Finish',
        }),
        saveChanges: intl.formatMessage({
          id: 'action.saveChanges',
          defaultMessage: 'Save changes',
        }),
        cancel: intl.formatMessage({
          id: 'action.cancel',
          defaultMessage: 'Cancel',
        }),
        confirm: intl.formatMessage({
          id: 'action.confirm',
          defaultMessage: 'Confirm',
        }),
        discard: intl.formatMessage({
          id: 'action.discard',
          defaultMessage: 'Discard',
        }),
        callBack: intl.formatMessage({
          id: 'action.callBack',
          defaultMessage: 'Callback',
        }),
        open: intl.formatMessage({
          id: 'action.open',
          defaultMessage: 'Open',
        }),
        resetPassword: intl.formatMessage({
          id: 'resetPassword.submit',
          defaultMessage: 'Reset password',
        }),
        login: intl.formatMessage({
          id: 'login.submit',
          defaultMessage: 'Login',
        }),
        acceptInvite: intl.formatMessage({
          id: 'acceptInvite.submit',
          defaultMessage: 'Go to the system',
        }),
        apply: intl.formatMessage({
          id: 'action.apply',
          defaultMessage: 'Apply',
        }),
        forceConnect: intl.formatMessage({
          id: 'action.forceConnect',
          defaultMessage: 'Connect',
        }),
        forceDisconnect: intl.formatMessage({
          id: 'action.forceDisconnect',
          defaultMessage: 'Disconnect',
        }),
        take: intl.formatMessage({
          id: 'action.take',
          defaultMessage: 'Take',
        }),
        listen: intl.formatMessage({
          id: 'action.listen',
          defaultMessage: 'Listen',
        }),
        drop: intl.formatMessage({
          id: 'action.drop',
          defaultMessage: 'Drop',
        }),
        copyInviteLink: intl.formatMessage({
          id: 'users.action.copyInviteLink',
          defaultMessage: 'Copy invite link',
        }),
        forgotPassword: intl.formatMessage({
          id: 'login.forgotPassword',
          defaultMessage: 'Forgot password',
        }),
        forgotPasswordSubmit: intl.formatMessage({
          id: 'forgotPassword.submit',
          defaultMessage: 'Send email',
        }),
        dangerActionTitle: intl.formatMessage({
          id: 'dangerAction.title',
          defaultMessage: 'Confirmation required',
        }),
        dangerActionBody: intl.formatMessage({
          id: 'dangerAction.body',
          defaultMessage: 'Are you confirming this operation?',
        }),
        download: intl.formatMessage({
          id: 'action.download',
          defaultMessage: 'Download',
        }),
        editingIn: intl.formatMessage({
          id: 'editing.in',
          defaultMessage: 'Do edit',
        }),
        bindClient: intl.formatMessage({
          id: 'applications.new.bindClient',
          defaultMessage: 'Bind Client',
        }),
        astorSync: intl.formatMessage({
          id: 'setting.general.astorSync',
          defaultMessage: 'Astor sync data Base',
        }),
        astorDrop: intl.formatMessage({
          id: 'setting.general.astorDrop',
          defaultMessage: 'Drop Astor data to 10.08.2022 00:00:00',
        }),
        astorList: intl.formatMessage({
          id: 'astor.list',
          defaultMessage: 'Astor list',
        }),
        billingTitle: intl.formatMessage({
          id: 'billing',
          defaultMessage: 'Billing',
        }),
        billingGetClient: intl.formatMessage({
          id: 'billing.get.client',
          defaultMessage: 'Get client from billing',
        }),
        billingGetClientSuccess: {
          titles: (dateFrom: string, remName: string) =>
            intl.formatMessage(
              {
                id: 'billing.get.client.success',
                defaultMessage:
                  'Get client from billing from {dateFrom} and for rem {remName} is success',
              },
              {dateFrom, remName},
            ),
        },
        billingGetClientError: {
          titles: (dateFrom: string, remName: string) =>
            intl.formatMessage(
              {
                id: 'billing.get.client.error',
                defaultMessage:
                  'Get client from billing from {dateFrom} and for rem {remName} is unsuccessful',
              },
              {dateFrom, remName},
            ),
        },
        billingFromDate: intl.formatMessage({
          id: 'billing.get.client.dateFrom',
          defaultMessage: 'From date',
        }),
        billingRemName: intl.formatMessage({
          id: 'billing.get.client.rem.name',
          defaultMessage: 'Rem name',
        }),
        billingAllRems: intl.formatMessage({
          id: 'billing.get.client.all.rems',
          defaultMessage: 'All Rems',
        }),
        billingAllPeriods: intl.formatMessage({
          id: 'billing.get.client.all.periods',
          defaultMessage: 'All Periods',
        }),
      },
      //@application
      application: {
        titles: (id: number) =>
          intl.formatMessage(
            {
              id: 'application.title',
              defaultMessage: 'Application {id}',
            },
            {
              id,
            },
          ),
        newApplication: {
          title: intl.formatMessage({
            id: 'applications.new.title',
            defaultMessage: 'New application',
          }),
          accountNo: intl.formatMessage({
            id: 'applications.new.accountNo',
            defaultMessage: 'Account No',
          }),
        },
        notEmpty: intl.formatMessage({
          id: 'error.call.application.canNotBeEmpty',
          defaultMessage: 'Application can not be empty',
        }),
        callHistory: intl.formatMessage({
          id: 'call.history.number',
          defaultMessage: 'Call history',
        }),
        closed: intl.formatMessage({
          id: 'applications.closed',
          defaultMessage: 'Closed',
        }),
        closeApplication: intl.formatMessage({
          id: 'applications.close',
          defaultMessage: 'Close application',
        }),
        putOff: intl.formatMessage({
          id: 'applications.putoff',
          defaultMessage: 'Putt off application',
        }),
        createNewApplication: intl.formatMessage({
          id: 'applications.new.create',
          defaultMessage: 'Create new application',
        }),
        id: intl.formatMessage({
          id: 'label.id',
          defaultMessage: 'Id',
        }),
        client: intl.formatMessage({
          id: 'applications.client',
          defaultMessage: 'Client',
        }),
        subtopic: intl.formatMessage({
          id: 'applications.subtopic',
          defaultMessage: 'Subtopic',
        }),
        time: intl.formatMessage({
          id: 'applications.date',
          defaultMessage: 'Time',
        }),
        hint: intl.formatMessage({
          id: 'hint.no.information',
          defaultMessage: 'No information',
        }),
        safeBeforeDownload: intl.formatMessage({
          id: 'appeal.safe.before.download',
          defaultMessage: 'Safe before download',
        }),
        prevRequests: intl.formatMessage({
          id: 'call.history.client',
          defaultMessage: 'Previous Requests',
        }),
        addressDno: intl.formatMessage({
          id: 'applications.addressDNO',
          defaultMessage: 'Id DNO client',
        }),
        lastIndicator: intl.formatMessage({
          id: 'applications.lastIndicator',
          defaultMessage: 'Last reading of the counter',
        }),
        counterDigit: intl.formatMessage({
          id: 'applications.counterDigit',
          defaultMessage: 'Digit capacity of the electric meter',
        }),
        placeOfInstallation: intl.formatMessage({
          id: 'applications.placeOfInstallation',
          defaultMessage: 'Place of installation of the meter',
        }),
        stateConsumer: intl.formatMessage({
          id: 'applications.stateConsumer',
          defaultMessage: ' State of the electricity consumer',
        }),
        typeElectricalNetwork: intl.formatMessage({
          id: 'applications.typeElectricalNetwork',
          defaultMessage:
            'Еype of electrical network (two-phase, one-phase or three-phase)',
        }),
        allowedPower: intl.formatMessage({
          id: 'applications.allowedPower',
          defaultMessage: 'Permitted power',
        }),
        overdueSupplierDebt: intl.formatMessage({
          id: 'applications.overdueSupplierDebt',
          defaultMessage: 'Overdue supplier debt',
        }),
        date: intl.formatMessage({
          id: 'indicator.date',
          defaultMessage: 'Date',
        }),
        indicatorSubmittedSuccessfully: intl.formatMessage({
          id: 'indicator.submitted.successfully',
          defaultMessage: 'Indicator has been submitted successfully',
        }),
        indicator: intl.formatMessage({
          id: 'indicator.indicator',
          defaultMessage: 'Indicator',
        }),
        dateFrom: intl.formatMessage({
          id: 'indicator.dateFrom',
          defaultMessage: 'Date from ',
        }),
        dateTo: intl.formatMessage({
          id: 'indicator.dateTo',
          defaultMessage: 'Date to',
        }),
        value: intl.formatMessage({
          id: 'indicator.value',
          defaultMessage: 'Value indicator',
        }),
        note: intl.formatMessage({
          id: 'indicator.note',
          defaultMessage: 'Note to indicator',
        }),
        employer: intl.formatMessage({
          id: 'indicator.employer',
          defaultMessage: 'The employee recorded the indicator',
        }),
        clientIndicators: intl.formatMessage({
          id: 'applications.client.indicators',
          defaultMessage: 'History indicators client',
        }),
        prevApps: intl.formatMessage({
          id: 'applications.client.indicators.by.number',
          defaultMessage: 'Application with counter indicators',
        }),
        defaultTopic: intl.formatMessage({
          id: 'applications.default.topic',
          defaultMessage: 'Entering the indicator',
        }),
        phoneNumberUpdatedSuccessfully: intl.formatMessage({
          id: 'application.phone.number.updated.successfully',
          defaultMessage: 'Phone number has been updated successfully',
        }),
        closedApplication: intl.formatMessage({
          id: 'applications.closed',
          defaultMessage: 'Closed',
        }),
        phoneNumberIsNotValid: intl.formatMessage({
          id: 'application.phone.number.is.not.valid',
          defaultMessage: 'Phone number is not valid',
        }),
        energySuite: intl.formatMessage({
          id: 'button.energy.suite',
          defaultMessage: 'Energy Suite',
        }),
      },
      //@labels
      labels: {
        back: intl.formatMessage({
          id: 'action.back',
          defaultMessage: 'Go back',
        }),
        newIndicator: intl.formatMessage({
          id: 'label.newIndicator',
          defaultMessage: 'New indicator',
        }),
        night: intl.formatMessage({
          id: 'label.night',
          defaultMessage: 'Night',
        }),
        peak: intl.formatMessage({
          id: 'label.peakOfTheDay',
          defaultMessage: 'Peak',
        }),
        format: intl.formatMessage({
          id: 'format',
          defaultMessage: 'Format',
        }),
        generate: intl.formatMessage({
          id: 'generate',
          defaultMessage: 'Generate',
        }),
        generatedText: intl.formatMessage({
          id: 'generated.conversation.text',
          defaultMessage: 'Generated conversation text',
        }),
        sendClientMessage: intl.formatMessage({
          id: 'sendClientMessage',
          defaultMessage: 'Sent message a to client',
        }),
        clientMessageType: intl.formatMessage({
          id: 'clientMessageType',
          defaultMessage: 'Choose Method of Sending message',
        }),
        message: intl.formatMessage({
          id: 'message',
          defaultMessage: 'Message',
        }),
        boundObjectNumber: intl.formatMessage({
          id: 'bonds.object.number',
          defaultMessage: 'Object number',
        }),
        boundClientNumber: intl.formatMessage({
          id: 'bonds.client.number',
          defaultMessage: 'Client number',
        }),

        saveChangeApp: intl.formatMessage({
          id: 'label.saveChanges',
          defaultMessage: 'Save changes',
        }),
        clearAllFields: intl.formatMessage({
          id: 'label.clear.all.fields',
          defaultMessage: 'clear all fields',
        }),
        dispatcher: intl.formatMessage({
          id: 'label.dispatcher',
          defaultMessage: 'Dispatcher',
        }),
        mailingStrategy: intl.formatMessage({
          id: 'label.mailing.strategy',
          defaultMessage: 'Mailing strategy',
        }),
        strategyName: (name: string) =>
          intl.formatMessage({
            id: `label.mailing.strategy.${name}`,
            defaultMessage: name,
          }),
        disabled: intl.formatMessage({
          id: 'label.disabled',
          defaultMessage: 'Disabled',
        }),
        allTopics: intl.formatMessage({
          id: 'label.all.topics',
          defaultMessage: 'All topics',
        }),
        topicFilter: intl.formatMessage({
          id: 'label.topic.filter',
          defaultMessage: 'Topic filter',
        }),
        notConnected: intl.formatMessage({
          id: 'label.not.connected',
          defaultMessage: 'Can not make a call, you are not connected',
        }),
        prevAppFromNumberNotFound: intl.formatMessage({
          id: 'label.prevApplicationsFromNumberNotFound',
          defaultMessage: 'Previous applications not found',
        }),
        prevAppFromClientNotFound: intl.formatMessage({
          id: 'label.prevApplicationsFromClient',
          defaultMessage: 'Previous application not found',
        }),
        callsIn: intl.formatMessage({
          id: 'label.callIn',
          defaultMessage: 'Call In',
        }),
        callsOut: intl.formatMessage({
          id: 'label.callOut',
          defaultMessage: 'Call out',
        }),
        date: intl.formatMessage({
          id: 'label.date',
          defaultMessage: 'Date',
        }),
        rem: intl.formatMessage({
          id: 'applications.new.rem',
          defaultMessage: 'Rem',
        }),
        chart: intl.formatMessage({
          id: 'reports.advancedSLA.chart',
          defaultMessage: 'Chart',
        }),
        regime: intl.formatMessage({
          id: 'regime',
          defaultMessage: 'Regime',
        }),
        regimeSimple: intl.formatMessage({
          id: 'regime.simple',
          defaultMessage: 'Simple',
        }),
        regimeAdvanced: intl.formatMessage({
          id: 'regime.advanced',
          defaultMessage: 'Advanced',
        }),
        search: intl.formatMessage({
          id: 'label.search',
          defaultMessage: 'Search...',
        }),
        totalAppsWithIndicatorsFromViber: (
          from: string,
          to: string,
          count: number,
        ) =>
          intl.formatMessage(
            {
              id: 'label.totalAppsWithIndicators.from.viber',
              defaultMessage:
                'Total applications with indicators received from Viber',
            },
            {
              from: `<strong>${from}</strong>`,
              to: `<strong>${to}</strong>`,
              count: `<strong>${count}</strong>`,
            },
          ),
        totalAppsWithIndicatorsFromTelegram: (
          from: string,
          to: string,
          count: number,
        ) =>
          intl.formatMessage(
            {
              id: 'label.totalAppsWithIndicators.from.telegram',
              defaultMessage:
                'Total applications with indicators received from Telegram',
            },
            {
              from: `<strong>${from}</strong>`,
              to: `<strong>${to}</strong>`,
              count: `<strong>${count}</strong>`,
            },
          ),
        totalAppsWithIndicatorsFromInCalls: (
          from: string,
          to: string,
          count: number,
        ) =>
          intl.formatMessage(
            {
              id: 'label.totalAppsWithIndicators.from.incomingCalls',
              defaultMessage:
                'Total applications with indicators received from incoming calls',
            },
            {
              from: `<strong>${from}</strong>`,
              to: `<strong>${to}</strong>`,
              count: `<strong>${count}</strong>`,
            },
          ),
        totalAppsWithIndicatorsInAutoMode: (
          from: string,
          to: string,
          count: number,
        ) =>
          intl.formatMessage(
            {
              id: 'label.totalAppsWithIndicators.in.autoMode',
              defaultMessage:
                'Total applications with indicators received in automatic mode',
            },
            {
              from: `<strong>${from}</strong>`,
              to: `<strong>${to}</strong>`,
              count: `<strong>${count}</strong>`,
            },
          ),
        totalAppsWithIndicatorsFromOutCalls: (
          from: string,
          to: string,
          count: number,
        ) =>
          intl.formatMessage(
            {
              id: 'label.totalAppsWithIndicators.from.outgoingCalls',
              defaultMessage:
                'Total applications with indicators received from outgoing calls',
            },
            {
              from: `<strong>${from}</strong>`,
              to: `<strong>${to}</strong>`,
              count: `<strong>${count}</strong>`,
            },
          ),
        totalAps: (from: string, to: string, count: number) =>
          intl.formatMessage(
            {
              id: 'label.totalApps',
              defaultMessage: 'Total applications count',
            },
            {
              from: `<strong>${from}</strong>`,
              to: `<strong>${to}</strong>`,
              count: `<strong>${count}</strong>`,
            },
          ),
        totalInCalls: (from: string, to: string, count: number) =>
          intl.formatMessage(
            {
              id: 'label.totalInCalls',
              defaultMessage: 'Total incoming calls count',
            },
            {
              from: `<strong>${from}</strong>`,
              to: `<strong>${to}</strong>`,
              count: `<strong>${count}</strong>`,
            },
          ),
        totalInCallsConsider: (from: string, to: string, count: number) =>
          intl.formatMessage(
            {
              id: 'label.totalInCallsConsider',
              defaultMessage: 'Total incoming calls with consider count',
            },
            {
              from: `<strong>${from}</strong>`,
              to: `<strong>${to}</strong>`,
              count: `<strong>${count}</strong>`,
            },
          ),
        totalAppsConsider: (from: string, to: string, count: number) =>
          intl.formatMessage(
            {
              id: 'label.totalAppsConsider',
              defaultMessage: 'Total applications with consider count',
            },
            {
              from: `<strong>${from}</strong>`,
              to: `<strong>${to}</strong>`,
              count: `<strong>${count}</strong>`,
            },
          ),
        totalOutCalls: (from: string, to: string, count: number) =>
          intl.formatMessage(
            {
              id: 'label.totalOutCalls',
              defaultMessage: 'Total outgoing calls count',
            },
            {
              from: `<strong>${from}</strong>`,
              to: `<strong>${to}</strong>`,
              count: `<strong>${count}</strong>`,
            },
          ),
        totalLostCall: (from: string, to: string, count: number) =>
          intl.formatMessage(
            {
              id: 'label.totalLostCalls',
              defaultMessage: 'Total lost calls count',
            },
            {
              from: `<strong>${from}</strong>`,
              to: `<strong>${to}</strong>`,
              count: `<strong>${count}</strong>`,
            },
          ),
        totalAppsFromEmail: (from: string, to: string, count: number) =>
          intl.formatMessage(
            {
              id: 'label.totalAppsFromEmail',
              defaultMessage: 'Total applications with email count',
            },
            {
              from: `<strong>${from}</strong>`,
              to: `<strong>${to}</strong>`,
              count: `<strong>${count}</strong>`,
            },
          ),
        loading: intl.formatMessage({
          id: 'loading',
          defaultMessage: 'Loading...',
        }),
        permissions: (id: unknown) => intl.formatMessage({id: id as string}),
        name: intl.formatMessage({
          id: 'label.name',
          defaultMessage: 'Name',
        }),
        id: intl.formatMessage({
          id: 'label.id',
          defaultMessage: 'ID',
        }),
        phoneNumber: intl.formatMessage({
          id: 'label.phoneNumber',
          defaultMessage: 'Phone number',
        }),
        topic: intl.formatMessage({
          id: 'label.topic',
          defaultMessage: 'topic',
        }),
        comment: intl.formatMessage({
          id: 'label.comment',
          defaultMessage: 'Comment',
        }),
        astorComment: intl.formatMessage({
          id: 'label.astorComment',
          defaultMessage: 'Astor operator comment',
        }),
        applicationResult: intl.formatMessage({
          id: 'label.application.result',
          defaultMessage: 'Result',
        }),
        table: intl.formatMessage({
          id: 'label.table',
          defaultMessage: 'Table',
        }),
        number: intl.formatMessage({
          id: 'label.number',
          defaultMessage: 'Number',
        }),
        operator: intl.formatMessage({
          id: 'label.operator',
          defaultMessage: 'Operator',
        }),
        clientName: intl.formatMessage({
          id: 'label.clientName',
          defaultMessage: 'Client name',
        }),
        result: intl.formatMessage({
          id: 'label.result',
          defaultMessage: 'Result',
        }),
        direction: intl.formatMessage({
          id: 'label.direction',
          defaultMessage: 'Direction',
        }),
        rate: intl.formatMessage({
          id: 'label.rate',
          defaultMessage: 'Rate',
        }),
        user: intl.formatMessage({
          id: 'label.user',
          defaultMessage: 'User',
        }),
        callIn: intl.formatMessage({
          id: 'label.call.in',
          defaultMessage: 'Call in',
        }),
        callOut: intl.formatMessage({
          id: 'label.call.out',
          defaultMessage: 'Call out',
        }),
        in: intl.formatMessage({
          id: 'label.call.in',
          defaultMessage: 'Call in',
        }),
        out: intl.formatMessage({
          id: 'label.call.out',
          defaultMessage: 'Call out',
        }),

        roleName: intl.formatMessage({
          id: 'label.roleName',
          defaultMessage: 'Role name',
        }),
        address: {
          withoutFlat: (values: Record<string, string>) =>
            intl.formatMessage(
              {
                id: 'address.withoutFlat',
                defaultMessage: '{raion}, {settlement}, {street}, {buildingNo}',
              },
              values,
            ),
          withFlat: (values: Record<string, string>) =>
            intl.formatMessage(
              {
                id: 'address.withoutFlat',
                defaultMessage:
                  '{raion}, {settlement}, {street}, {buildingNo}/{flatNo}',
              },
              values,
            ),
        },
        sip: intl.formatMessage({
          id: 'label.sip',
          defaultMessage: 'Sip',
        }),
        incomingCall: intl.formatMessage({
          id: 'label.incomingCall',
          defaultMessage: 'Incoming call',
        }),
        listOfPendingApplications: intl.formatMessage({
          id: 'label.listOfPendingApplications',
          defaultMessage: 'List of pending applications',
        }),
        sipTrunks: {
          name: intl.formatMessage({
            id: 'sip-trunks.name',
            defaultMessage: 'Name',
          }),
          codec: intl.formatMessage({
            id: 'sip-trunks.codec',
            defaultMessage: 'Codec',
          }),
          transport: intl.formatMessage({
            id: 'sip-trunks.transport',
            defaultMessage: 'Transport',
          }),
          serverUrl: intl.formatMessage({
            id: 'sip-trunks.server.url',
            defaultMessage: 'Server url',
          }),
        },
        sourceOfIncome: (name: string) =>
          intl.formatMessage({
            id: `label.source.of.income.${name}`,
            defaultMessage: name,
          }),
      },
      //@title
      title: {
        forgotPassword: intl.formatMessage({
          id: 'forgotPassword.title',
          defaultMessage: 'Forgot password',
        }),
        login: intl.formatMessage({
          id: 'login.title',
          defaultMessage: 'Login',
        }),
        acceptInvitation: intl.formatMessage({
          id: 'acceptInvite.title',
          defaultMessage: 'Accept invitation',
        }),
        editingAgree: intl.formatMessage({
          id: 'editing.agree',
          defaultMessage: 'agree with changes',
        }),
        editingAut: intl.formatMessage({
          id: 'editing.aut',
          defaultMessage: 'Do not do edit',
        }),
        resetPassword: intl.formatMessage({
          id: 'resetPassword.title',
          defaultMessage: 'Reset Password',
        }),
        testPhone: intl.formatMessage({
          id: 'testPhone.title',
          defaultMessage: 'Test phone',
        }),
        tasks: intl.formatMessage({
          id: 'tasks.title',
          defaultMessage: 'Task',
        }),
        profile: intl.formatMessage({
          id: 'profile.title',
          defaultMessage: 'Profile',
        }),
        appealNumber: intl.formatMessage({
          id: 'appeal.number',
          defaultMessage: 'Number',
        }),
        appealCallId: intl.formatMessage({
          id: 'appeal.call.id',
          defaultMessage: 'Call number',
        }),
        appealFullName: intl.formatMessage({
          id: 'appeal.call.processedBy.fullName',
          defaultMessage: 'Operator name',
        }),
        appealFromNumber: intl.formatMessage({
          id: 'appeal.call.fromNumber',
          defaultMessage: 'Phone number',
        }),
        appealClientName: intl.formatMessage({
          id: 'appeal.application.client.name',
          defaultMessage: 'Client phone number',
        }),
        appealUserAddress: intl.formatMessage({
          id: 'appeal.user.address',
          defaultMessage: 'User address',
        }),
        appealTopic: intl.formatMessage({
          id: 'appeal.application.topic.name',
          defaultMessage: 'Topic',
        }),
        appealComment: intl.formatMessage({
          id: 'appeal.application.comment',
          defaultMessage: 'comment',
        }),
        appealOperator: intl.formatMessage({
          id: 'appeal.consideredBy.fullName',
          defaultMessage: 'Operator',
        }),
        appealDownloadWarning: intl.formatMessage({
          id: 'warning.download.appeal',
          defaultMessage: 'Please save changes to download an appeal!',
        }),
        ConsiderationResult: intl.formatMessage({
          id: 'appeal.application.topic.response',
          defaultMessage: 'Consideration result',
        }),
        appealAnswerDate: intl.formatMessage({
          id: 'appeal.answerDate',
          defaultMessage: 'Answer date',
        }),
        appealAnswerType: intl.formatMessage({
          id: 'appeal.answerType',
          defaultMessage: 'Answer type',
        }),
        appealCallCreatedAt: intl.formatMessage({
          id: 'appeal.call.createdAt',
          defaultMessage: 'Call created time',
        }),
        applicationsProcessing: intl.formatMessage({
          id: 'applications.processing.appeals',
          defaultMessage: 'Call created time',
        }),
      },
      //@tasks
      tasks: {
        empty: intl.formatMessage({
          id: 'tasks.empty',
          defaultMessage: 'Everything is done',
        }),
        incomingCall: (id: number) =>
          intl.formatMessage(
            {
              id: 'tasks.incomingCall',
              defaultMessage: 'Finish incoming call №{id}',
            },
            {
              id,
            },
          ),
      },
      //@fields
      fields: {
        roles: intl.formatMessage({
          id: 'settings.roles.title',
          defaultMessage: 'Roles',
        }),
        username: intl.formatMessage({
          id: 'label.username',
          defaultMessage: 'User name',
        }),
        firstName: intl.formatMessage({
          id: 'users.firstName',
          defaultMessage: 'First name',
        }),
        lastName: intl.formatMessage({
          id: 'users.lastName',
          defaultMessage: 'Last name',
        }),
        fatherName: intl.formatMessage({
          id: 'users.patronim',
          defaultMessage: 'Father name',
        }),
        fullName: intl.formatMessage({
          id: 'users.fullName',
          defaultMessage: 'Full name',
        }),
        password: intl.formatMessage({
          id: 'login.password',
          defaultMessage: 'password',
        }),
        currentPassword: intl.formatMessage({
          id: 'profile.password.currentPassword.label',
          defaultMessage: 'Current password',
        }),
        newPassword: intl.formatMessage({
          id: 'profile.password.newPassword.label',
          defaultMessage: 'Password',
        }),
        confirmPassword: intl.formatMessage({
          id: 'resetPassword.confirm',
          defaultMessage: 'Confirm',
        }),
        email: intl.formatMessage({
          id: 'users.email',
          defaultMessage: 'E-mail',
        }),
        periodSelect: {
          from: intl.formatMessage({
            id: 'periodSelect.from',
            defaultMessage: 'From',
          }),
          to: intl.formatMessage({
            id: 'periodSelect.to',
            defaultMessage: 'To',
          }),
          period: intl.formatMessage({
            id: 'periodSelect.period',
            defaultMessage: 'Period',
          }),
          custom: intl.formatMessage({
            id: 'periodSelect.custom',
            defaultMessage: 'Custom',
          }),
        },
      },
      //@date
      date: {
        label: {
          alls: intl.formatMessage({
            id: 'label.alls',
            defaultMessage: 'All',
          }),
          all: intl.formatMessage({
            id: 'all',
            defaultMessage: 'All',
          }),
          day: intl.formatMessage({
            id: 'label.day',
            defaultMessage: 'Day',
          }),
          month: intl.formatMessage({
            id: 'label.month',
            defaultMessage: 'Month',
          }),
          year: intl.formatMessage({
            id: 'label.year',
            defaultMessage: 'Year',
          }),
          week: intl.formatMessage({
            id: 'label.week',
            defaultMessage: 'Week',
          }),
          today: intl.formatMessage({
            id: 'label.today',
            defaultMessage: 'Today',
          }),
        },
        dateParts: {
          years: intl.formatMessage({
            id: 'datepart.years',
            defaultMessage: 'Years',
          }),
          quarters: intl.formatMessage({
            id: 'datepart.quarters',
            defaultMessage: 'Quarters',
          }),
          months: intl.formatMessage({
            id: 'datepart.months',
            defaultMessage: 'Months',
          }),
          weeks: intl.formatMessage({
            id: 'datepart.weeks',
            defaultMessage: 'Weeks',
          }),
          days: intl.formatMessage({
            id: 'datepart.days',
            defaultMessage: 'Days',
          }),
          hours: intl.formatMessage({
            id: 'datepart.hours',
            defaultMessage: 'Hours',
          }),
          minutes: intl.formatMessage({
            id: 'datepart.minutes',
            defaultMessage: 'Minutes',
          }),
        },
        datePartsShort: {
          seconds: intl.formatMessage({
            id: 'datePartShort.seconds',
            defaultMessage: 'sec',
          }),
          minutes: intl.formatMessage({
            id: 'datePartShort.minutes',
            defaultMessage: 'min',
          }),
          hours: intl.formatMessage({
            id: 'datePartShort.hours',
            defaultMessage: 'hrs',
          }),
          days: intl.formatMessage({
            id: 'datePartShort.days',
            defaultMessage: 'd',
          }),
          weeks: intl.formatMessage({
            id: 'datePartShort.weeks',
            defaultMessage: 'wk',
          }),
          months: intl.formatMessage({
            id: 'datePartShort.months',
            defaultMessage: 'mo',
          }),
          years: intl.formatMessage({
            id: 'datePartShort.years',
            defaultMessage: 'yr',
          }),
        },
        dateStart: intl.formatMessage({
          id: 'dateRangePicker.date.start',
          defaultMessage: 'Start date',
        }),
        dateEnd: intl.formatMessage({
          id: 'dateRangePicker.date.end',
          defaultMessage: 'Date end',
        }),
        timeTo: intl.formatMessage({
          id: 'reports.operator.work.timeTo',
          defaultMessage: 'Time to',
        }),
        timeFrom: intl.formatMessage({
          id: 'reports.operator.work.timeFrom',
          defaultMessage: 'Time form',
        }),
        dayShort: intl.formatMessage({
          id: 'day.short',
          defaultMessage: 'Day/s',
        }),
        year: (year: number) =>
          intl.formatMessage(
            {
              id: 'year',
              defaultMessage: '{number} year',
            },
            {
              number: year,
            },
          ),
        week: (week: number) =>
          intl.formatMessage(
            {
              id: 'week',
              defaultMessage: 'week {number}',
            },
            {
              number: week,
            },
          ),
        days: (day: number) =>
          intl.formatMessage(
            {
              id: 'day',
              defaultMessage: '{number} day',
            },
            {
              number: day,
            },
          ),
        hour: (hour: number) =>
          intl.formatMessage(
            {
              id: 'hour',
              defaultMessage: '{number} hour',
            },
            {
              number: hour,
            },
          ),
        quarter: (quarter: number) =>
          intl.formatMessage(
            {
              id: 'quarter',
              defaultMessage: '{quarter} quarter',
            },
            {
              quarter,
            },
          ),
        months: {
          january: intl.formatMessage({
            id: 'month.January',
            defaultMessage: 'January',
          }),
          february: intl.formatMessage({
            id: 'month.February',
            defaultMessage: 'February',
          }),
          march: intl.formatMessage({
            id: 'month.March',
            defaultMessage: 'March',
          }),
          april: intl.formatMessage({
            id: 'month.April',
            defaultMessage: 'April',
          }),
          may: intl.formatMessage({
            id: 'month.May',
            defaultMessage: 'May',
          }),
          june: intl.formatMessage({
            id: 'month.June',
            defaultMessage: 'June',
          }),
          july: intl.formatMessage({
            id: 'month.July',
            defaultMessage: 'July',
          }),
          august: intl.formatMessage({
            id: 'month.August',
            defaultMessage: 'August',
          }),
          september: intl.formatMessage({
            id: 'month.September',
            defaultMessage: 'September',
          }),
          october: intl.formatMessage({
            id: 'month.October',
            defaultMessage: 'October',
          }),
          november: intl.formatMessage({
            id: 'month.November',
            defaultMessage: 'November',
          }),
          december: intl.formatMessage({
            id: 'month.December',
            defaultMessage: 'December',
          }),
        },
      },
      //@errors
      errors: {
        indicatorValidationError: intl.formatMessage({
          id: 'error.indicator.validation',
          defaultMessage: 'Invalid indicator',
        }),
        loadError: intl.formatMessage({
          id: 'error.load.data',
          defaultMessage: 'Error loading data',
        }),
        pageIsNotAvailable: intl.formatMessage({
          id: 'error.pageIsNotAvailable',
          defaultMessage: 'Page is not available',
        }),
        deleteTopics: intl.formatMessage({
          id: 'error.permission:delete:topics',
          defaultMessage: 'You dont have permission to delete topics',
        }),
        editTopics: intl.formatMessage({
          id: 'error.permission:edit:topics',
          defaultMessage: 'You dont have permission to edit topics',
        }),
        createTopics: intl.formatMessage({
          id: 'error.permission:create:topics',
          defaultMessage: 'You dont have permission to create topics',
        }),
        editScheduleException: intl.formatMessage({
          id: 'error.permission:edit:scheduleException',
          defaultMessage: 'You dont have permission to edit schedule exception',
        }),
        createSchedule: intl.formatMessage({
          id: 'error.permission:create:schedule',
          defaultMessage: 'You dont have permission to create schedule',
        }),
        editSchedule: intl.formatMessage({
          id: 'error.permission:edit:schedule',
          defaultMessage: 'You dont have permission to edit schedule',
        }),
        deleteSchedule: intl.formatMessage({
          id: 'error.permission:delete:schedule',
          defaultMessage: 'You dont have permission to delete schedule',
        }),
        createBlackList: intl.formatMessage({
          id: 'error.permission:create:blacklist',
          defaultMessage: 'You dont have permission to create blacklist',
        }),
        editBlackList: intl.formatMessage({
          id: 'error.permission:edit:blacklist',
          defaultMessage: 'You dont have permission to edit blacklist',
        }),
        deleteBlackList: intl.formatMessage({
          id: 'error.permission:delete:blacklist',
          defaultMessage: 'You dont have permission to delete blacklist',
        }),
        deleteTrunks: intl.formatMessage({
          id: 'error.permission:delete:trunks',
          defaultMessage: 'You dont have permission to delete trunks',
        }),
        createTrunks: intl.formatMessage({
          id: 'error.permission:create:trunks',
          defaultMessage: 'You dont have permission to create trunks',
        }),
        editTrunks: intl.formatMessage({
          id: 'error.permission:edit:trunks',
          defaultMessage: 'You dont have permission to edit trunks',
        }),
        deleteRoute: intl.formatMessage({
          id: 'error.permission:delete:route',
          defaultMessage: 'You dont have permission delete route',
        }),
        createRoute: intl.formatMessage({
          id: 'error.permission:create:route',
          defaultMessage: 'You dont have permission create route',
        }),
        editRoute: intl.formatMessage({
          id: 'error.permission:edit:route',
          defaultMessage: 'You dont have permission edit route',
        }),

        deleteRule: intl.formatMessage({
          id: 'error.permission:delete:rule',
          defaultMessage: 'You dont have permission delete rule',
        }),
        createRule: intl.formatMessage({
          id: 'error.permission:create:rule',
          defaultMessage: 'You dont have permission create rule',
        }),
        editRule: intl.formatMessage({
          id: 'error.permission:edit:rule',
          defaultMessage: 'You dont have permission edit rule',
        }),

        userDeactivated: intl.formatMessage({
          id: 'error.userDeactivated',
          defaultMessage: 'User deactivated',
        }),
        isRequired: intl.formatMessage({
          id: 'error.fieldIsRequired',
          defaultMessage: 'Field is required',
        }),
        roleNameIsRequired: intl.formatMessage({
          id: 'error.roleNameIsRequired',
          defaultMessage: 'Role name is required',
        }),
        roleMustHaveAtLeastOnePermission: intl.formatMessage({
          id: 'error.roleMustHaveAtLeastOnePermission',
          defaultMessage: 'Role must have at least one permission',
        }),
        userNotFound: intl.formatMessage({
          id: 'error.userNotFound',
          defaultMessage: 'User not found',
        }),
        emailAlreadyExist: intl.formatMessage({
          id: 'error.emailAlreadyExist',
          defaultMessage: 'Email already exist',
        }),
        userNameAlreadyExist: intl.formatMessage({
          id: 'error.userNameAlreadyExist',
          defaultMessage: 'User with this username already exist',
        }),
        userWithEmailNotExist: intl.formatMessage({
          id: 'error.userWithEmailNotExist',
          defaultMessage: 'User with this email not exist',
        }),
        invalidNumber: intl.formatMessage({
          id: 'error.validation.invalidNumber',
          defaultMessage: 'Invalid number',
        }),
        numberIsRequired: intl.formatMessage({
          id: 'error.validation.numberIsRequired',
          defaultMessage: 'Number is required',
        }),
        reasonIsRequired: intl.formatMessage({
          id: 'error.validation.reasonIsRequired',
          defaultMessage: 'Reason is required',
        }),
        roleNameIsExist: intl.formatMessage({
          id: 'role.name.error',
          defaultMessage: 'Role name exist',
        }),
        createTopicError: intl.formatMessage({
          id: 'error.createTopic',
          defaultMessage: 'Error creating topic',
        }),
        editTopicError: intl.formatMessage({
          id: 'error.editTopic',
          defaultMessage: 'Error editing topic',
        }),
        deleteTopicError: intl.formatMessage({
          id: 'error.deleteTopic',
          defaultMessage: 'Error deleting topic',
        }),
        invalidFormat: (format: string) =>
          intl.formatMessage(
            {
              id: 'error.invalidFormat',
              defaultMessage: 'Invalid format',
            },
            {format},
          ),
        passwordContainLowerAndUpper: intl.formatMessage({
          id: 'profile.password.error.register',
          defaultMessage: 'Use upper and lower case letters',
        }),
        passwordContainNumber: intl.formatMessage({
          id: 'profile.password.error.numb',
          defaultMessage: 'Use number',
        }),
        passwordOrEmailInvalid: intl.formatMessage({
          id: 'error.invalidUsernameOrPassword',
          defaultMessage: 'Password or Email is invalid',
        }),
        passwordMustMatch: intl.formatMessage({
          id: 'profile.password.error.confirm',
          defaultMessage: 'Password must match',
        }),
        passwordIsNotSame: intl.formatMessage({
          id: 'profile.password.error.isSame',
          defaultMessage: 'Password must different then previous',
        }),
        passwordContainSpecialCharacters: intl.formatMessage({
          id: 'profile.password.error.spec',
          defaultMessage: 'Use special characters',
        }),
        currentPasswordInvalid: intl.formatMessage({
          id: 'profile.currentPassword.error.invalid',
          defaultMessage: 'Current password is invalid',
        }),
        unknownValidationError: intl.formatMessage({
          id: 'error.unknownValidation',
          defaultMessage: 'Unknown validation error',
        }),
        wrongFormat: intl.formatMessage({
          id: 'numbersEditor.wrongFormat',
          defaultMessage: 'Wrong format',
        }),
        noBoundNumber: intl.formatMessage({
          id: 'numbersEditor.noBoundNumbers',
          defaultMessage: 'No bound numbers',
        }),
        numberIsExist: intl.formatMessage({
          id: 'numbersEditor.numberIsExsist',
          defaultMessage: 'Number is already assignet',
        }),
        passwordMustBeMinChars: (length: number) =>
          intl.formatMessage(
            {
              id: 'profile.passwordMustBenMinChars',
              defaultMessage: 'Password must be al least {length} characters',
            },
            {length},
          ),
        fieldIsRequired: (field: string) =>
          intl.formatMessage(
            {
              id: 'error.fieldIsRequired',
              defaultMessage: '{field} is required',
            },
            {field},
          ),
        notEmail: intl.formatMessage({
          id: 'error.email',
          defaultMessage: 'Invalid e-mail address',
        }),
        invalidDate: intl.formatMessage({
          id: 'error.date.invalid',
          defaultMessage: 'Invalid date format',
        }),
        error500: intl.formatMessage({
          id: 'error.500',
          defaultMessage: 'Internal Server Error. Try to reload the page.',
        }),
        badRequest: intl.formatMessage({
          id: 'error.badRequest',
          defaultMessage: 'Cannot POST',
        }),
        notFound: intl.formatMessage({
          id: 'error.400',
          defaultMessage: 'Not Found',
        }),
        chatWithNameAlreadyExist: intl.formatMessage({
          id: 'chat.error.already.exist',
          defaultMessage: 'Chat with this name already exist',
        }),
        chatMessageWasNotCopied: intl.formatMessage({
          id: 'chat.message.copy.error',
          defaultMessage: 'Message was not copied!',
        }),
        fileTooLarge: intl.formatMessage({
          id: 'file.large.error',
          defaultMessage: 'File size exceeds the allowed limit (5 MB)',
        }),
        uploadFailed: intl.formatMessage({
          id: 'file.upload.error',
          defaultMessage: 'File upload error. Please try again',
        }),
      },
      //@message
      message: {
        messageSent: intl.formatMessage({
          id: 'messageSent',
          defaultMessage: 'Message has been send',
        }),
        applicationCreate: intl.formatMessage({
          id: 'message.application.create',
          defaultMessage: 'Application created',
        }),
        applicationSaveChanges: intl.formatMessage({
          id: 'message.application.savedChanges',
          defaultMessage: 'Application changes saved',
        }),
        applicationSaveChangesError: intl.formatMessage({
          id: 'message.application.saveChangesError',
          defaultMessage: 'An error occurred while saving application changes',
        }),
        emptyChatListTitle: intl.formatMessage({
          id: 'message.empty.chat.list',
          defaultMessage: 'Chat list is empty...',
        }),
        emptyChatListMessage: intl.formatMessage({
          id: 'message.empty.chat.list.message',
          defaultMessage: 'Please, create a new conversation',
        }),
        emptyChat: intl.formatMessage({
          id: 'chat.messages.empty',
          defaultMessage: 'No messages yet...',
        }),
        chatMessageCopied: intl.formatMessage({
          id: 'chat.message.copied.successfully',
          defaultMessage: 'Message copied',
        }),
        downloadXlsx: intl.formatMessage({
          id: 'message.tooltip.download.xlsx',
          defaultMessage: 'Download xlsx',
        }),
        downloadDocx: intl.formatMessage({
          id: 'message.tooltip.download.docx',
          defaultMessage: 'Download docx',
        }),
        totalFetched: (totalFetched: number, total: number) =>
          intl.formatMessage(
            {
              id: 'message.totalFetched',
              defaultMessage: `Fetched ${totalFetched} of ${total}`,
            },
            {totalFetched, total},
          ),
        changeClientNumber: intl.formatMessage({
          id: 'message.changeClientNumberMessage',
          defaultMessage: 'Change client number',
        }),
        changeEmptyClientNumber: intl.formatMessage({
          id: 'message.changeEmptyClientNumberMessage',
          defaultMessage: 'Change empty client number',
        }),
        userDeleted: intl.formatMessage({
          id: 'message.user.deleted',
          defaultMessage: 'User deleted',
        }),
        userDeactivated: intl.formatMessage({
          id: 'message.user.deactivated',
          defaultMessage: 'User deactivated',
        }),
        userEdited: intl.formatMessage({
          id: 'message.user.edited',
          defaultMessage: 'User successfully edited',
        }),
        userCreated: intl.formatMessage({
          id: 'message.user.created',
          defaultMessage: 'User successfully created',
        }),
        astorSendApplication: intl.formatMessage({
          id: 'message.sendApplicationToAstor',
          defaultMessage: 'Send application to Astor',
        }),
        astorSendApplicationToAstor: intl.formatMessage({
          id: 'message.sendAppToAstor',
          defaultMessage: 'Application sent to Astor',
        }),
        astorClientSync: intl.formatMessage({
          id: 'message.updateClientAstor',
          defaultMessage: 'synchronization clients with Astor was successful',
        }),
        astorDnoSync: intl.formatMessage({
          id: 'message.updateDnoAstor',
          defaultMessage: 'synchronization DNO with Astor was successful',
        }),
        astorClientDrop: intl.formatMessage({
          id: 'message.dropClientAstor',
          defaultMessage: 'Drop clients with Astor was successful',
        }),
        astorDnoDrop: intl.formatMessage({
          id: 'message.dropDnoAstor',
          defaultMessage: 'Drop DNO with Astor was successful',
        }),
        noDataWithFilters: intl.formatMessage({
          id: 'message.noDataWithThisFilters',
          defaultMessage: 'No data with this filters',
        }),
        noUsersFound: intl.formatMessage({
          id: 'message.noUsersFound',
          defaultMessage: 'Not one users not found',
        }),
        userNotActivated: intl.formatMessage({
          id: 'message.userNotActivated',
          defaultMessage: 'not activated',
        }),
        profileSuccess: intl.formatMessage({
          id: 'profile.success',
          defaultMessage: 'Your profile is updated',
        }),
        settingsUpdate: intl.formatMessage({
          id: 'message.settingUpdate',
          defaultMessage: 'Setting successfully update',
        }),
        resetPasswordDesc: intl.formatMessage({
          id: 'resetPassword.dialog.desc',
          defaultMessage:
            'Your password was successfully reset. Now you can log in with new password',
        }),
        forgotPasswordDecs: intl.formatMessage({
          id: 'forgotPassword.dialog.desc',
          defaultMessage:
            'We have just sent you an email with reset password link. Please, check your inbox or spam folder to find it. Reload the page to try again.',
        }),
        forgotPasswordAction: intl.formatMessage({
          id: 'forgotPassword.dialog.action',
          defaultMessage: 'Go to password recovery',
        }),
        welcome: intl.formatMessage({
          id: 'forgotPassword.dialog.title',
          defaultMessage: 'Welcome',
        }),
        noRowsFound: intl.formatMessage({
          id: 'message.noRowsFound',
          defaultMessage: 'No rows found',
        }),
        microphoneIsNotAvailable: intl.formatMessage({
          id: 'microphone.not.available',
          defaultMessage: 'Microphone not available',
        }),
        sendInviteLinkSuccess: intl.formatMessage({
          id: 'users.action.sendInviteLinkSuccess',
          defaultMessage: 'Invite has been successfully sent',
        }),
        copyInviteLinkSuccess: intl.formatMessage({
          id: 'users.action.copyInviteLinkSuccess',
          defaultMessage: 'Invite link has been successfully copied',
        }),
        changePasswordSuccess: intl.formatMessage({
          id: 'profile.changePassword.notify.success',
          defaultMessage: 'Password was updated successfully',
        }),
        acceptInvitationWelcome: intl.formatMessage({
          id: 'acceptInvite.welcome',
          defaultMessage:
            'Welcome to the system. Please, set the password and you can start working.',
        }),
        page404: intl.formatMessage({
          id: 'page404.message',
          defaultMessage: 'Page not found',
        }),
        noData: intl.formatMessage({
          id: 'message.noData',
          defaultMessage: 'No data',
        }),
        users: {
          create: intl.formatMessage({
            id: 'users.create.title',
            defaultMessage: 'Create new user',
          }),
          edit: intl.formatMessage({
            id: 'users.edit.title',
            defaultMessage: 'Edit user',
          }),
        },
        actions: {
          copyInviteLink: intl.formatMessage({
            id: 'users.action.copyInviteLink',
            defaultMessage: 'Copy invite link',
          }),
          roleAlert: intl.formatMessage({
            id: 'role.alert',
            defaultMessage: 'Are you confirming the deletion of this role?',
          }),
          adminAlert: intl.formatMessage({
            id: 'admin.alert',
            defaultMessage: 'This role is systemic and cannot be removed',
          }),
          deleteAgree: (field: string) =>
            intl.formatMessage(
              {
                id: 'actions.deleteAgree',
                defaultMessage: '{field} is required',
              },
              {field},
            ),
          deleteItem: intl.formatMessage({
            id: 'action.delete',
            defaultMessage: 'delete',
          }),
        },
        sip: {
          alreadyInQueue: intl.formatMessage({
            id: 'sip.alreadyInQueue',
            defaultMessage:
              'You have already connected to the queue in another tab or browser',
          }),
          connection: intl.formatMessage({
            id: 'sipStatus.connecting',
            defaultMessage: 'Connecting to server...',
          }),
          lostAsterisk: intl.formatMessage({
            id: 'sipStatus.lostAsterisk',
            defaultMessage: 'Lost connection to Asterisk. Reconnecting...',
          }),
          lostQueue: intl.formatMessage({
            id: 'sipStatus.lostQueue',
            defaultMessage: 'Lost connection to the queue. Reconnecting...',
          }),
          lostServer: intl.formatMessage({
            id: 'sipStatus.lostServer',
            defaultMessage:
              'Lost connection to server. Check your internet connection.',
          }),
          canNotConnection: intl.formatMessage({
            id: 'sipStatus.can’tConnection',
            defaultMessage: 'That operator cant accept incoming calls',
          }),
        },
        calls: {
          lines: intl.formatMessage({
            id: 'calls.dashboard.lines',
            defaultMessage: 'Lines State',
          }),
        },
        externalPhoneNumbers: {
          created: intl.formatMessage({
            id: 'externalPhoneNumbers.created',
            defaultMessage: 'Phone number created successfully',
          }),
          edited: intl.formatMessage({
            id: 'externalPhoneNumbers.edited',
            defaultMessage: 'Phone number edited successfully',
          }),
          deleted: intl.formatMessage({
            id: 'externalPhoneNumbers.deleted',
            defaultMessage: 'Phone number deleted successfully',
          }),
        },
      },
      //@reports
      reports: {
        qualitySupply: {
          title: intl.formatMessage({
            id: 'report.qualitySupply.title',
            defaultMessage: 'Quality supply',
          }),
          timeToQueue: intl.formatMessage({
            id: `reports.qualitySupply.column.timeToQueen`,
            defaultMessage: 'Time to queue',
          }),
          appCode: intl.formatMessage({
            id: 'reports.qualitySupply.column.appCode',
            defaultMessage: 'App code',
          }),
          callStart: intl.formatMessage({
            id: 'reports.qualitySupply.column.callStart',
            defaultMessage: 'Call start',
          }),
          callEnd: intl.formatMessage({
            id: 'reports.qualitySupply.column.callEnd',
            defaultMessage: 'Call end',
          }),
          subTopic: intl.formatMessage({
            id: 'reports.history.column.subTopics',
            defaultMessage: 'Subtopic',
          }),
          shotAppContent: intl.formatMessage({
            id: 'reports.qualitySupply.column.shotContent',
            defaultMessage: 'Shot app content',
          }),
          divisionOfNetwork: intl.formatMessage({
            id: 'reports.qualitySupply.column.divisionOfNetworks',
            defaultMessage: 'Division of network',
          }),
          placeOfConnection: intl.formatMessage({
            id: 'reports.qualitySupply.column.placeOfConnection',
            defaultMessage: 'Place of connection',
          }),
        },
        slaTitle: intl.formatMessage({
          id: 'reports.sla.title',
          defaultMessage: 'Service-level agreement report',
        }),
        operatorReportsToe: intl.formatMessage({
          id: 'reports.toe.operatorReports.title',
          defaultMessage: 'Operators Reports (TOE)',
        }),
        topicTitle: intl.formatMessage({
          id: 'reports.topics.title',
          defaultMessage: 'Report by topics',
        }),
        operatorWorkTitle: intl.formatMessage({
          id: 'reports.operatorWork.title',
          defaultMessage: 'Operator work report',
        }),
        advancedSla: intl.formatMessage({
          id: 'reports.advancedSLA.title',
          defaultMessage: 'Advanced SLA report',
        }),
        advanced: {
          interval: intl.formatMessage({
            id: 'reports.advancedReport.interval',
            defaultMessage: 'Interval',
          }),
          topicTitle: intl.formatMessage({
            id: 'reports.advancedTopics.title',
            defaultMessage: 'By Topic (advanced)',
          }),
        },
        astorPage: intl.formatMessage({
          id: 'astor.page.title',
          defaultMessage: 'Astor Page',
        }),
        astorClientsId: intl.formatMessage({
          id: 'astor.clients.id',
          defaultMessage: 'ID',
        }),
        astorClientsIdDno: intl.formatMessage({
          id: 'astor.clients.idDno',
          defaultMessage: 'Id Dno client',
        }),
        astorClientsNameDno: intl.formatMessage({
          id: 'astor.clients.nameDno',
          defaultMessage: 'Id Dno client',
        }),
        astorClientsAccountNo: intl.formatMessage({
          id: 'astor.clients.accountNo',
          defaultMessage: 'Clint account number',
        }),
        astorClientsName: intl.formatMessage({
          id: 'astor.clients.name',
          defaultMessage: 'Client name',
        }),
        astorClientsAddress: intl.formatMessage({
          id: 'astor.clients.address',
          defaultMessage: 'Client address',
        }),
        astorClientsDocNumber: intl.formatMessage({
          id: 'astor.clients.docNumber',
          defaultMessage: 'Client doc number',
        }),
        astorClientsEIC: intl.formatMessage({
          id: 'astor.clients.eic',
          defaultMessage: 'Client EIC',
        }),
        astorAccident: intl.formatMessage({
          id: 'accident',
          defaultMessage: 'Accident',
        }),
        accidentsSchedules: intl.formatMessage({
          id: 'accidents.scheduled',
          defaultMessage: 'Accidents schedules',
        }),
        accidentsEmergency: intl.formatMessage({
          id: 'accidents.emergency',
          defaultMessage: 'Accidents emergency',
        }),
        accidentsOperative: intl.formatMessage({
          id: 'accidents.operative',
          defaultMessage: 'Accidents operative',
        }),

        tableColumn: {
          field: intl.formatMessage({
            id: 'reports.advancedSLA.table.field',
            defaultMessage: 'Field',
          }),
          avgCallsPerOperator: intl.formatMessage({
            id: 'reports.advancedSLA.table.avgCallsPerOperator',
            defaultMessage: 'Average calls per operator',
          }),
          total: intl.formatMessage({
            id: 'reports.advancedSLA.table.total',
            defaultMessage: 'Total',
          }),
          received: intl.formatMessage({
            id: 'reports.advancedSLA.table.received',
            defaultMessage: 'Received',
          }),
          connected: intl.formatMessage({
            id: 'reports.advancedSLA.table.connected',
            defaultMessage: 'Connected',
          }),
          ivr: intl.formatMessage({
            id: 'reports.advancedSLA.table.ivr',
            defaultMessage: 'Ivr',
          }),
          lost: intl.formatMessage({
            id: 'reports.advancedSLA.table.lost',
            defaultMessage: 'Lost',
          }),
          thirtySecond: intl.formatMessage({
            id: 'reports.advancedSLA.table.30s',
            defaultMessage: '30s',
          }),
          sixtySecond: intl.formatMessage({
            id: 'reports.advancedSLA.table.60s',
            defaultMessage: '60s',
          }),
          lostPerc: intl.formatMessage({
            id: 'reports.advancedSLA.table.lostPerc',
            defaultMessage: 'Lost (%)',
          }),
          avgResponse: intl.formatMessage({
            id: 'reports.advancedSLA.table.avgResponse',
            defaultMessage: 'Average response',
          }),
          avgQueueAnswer: intl.formatMessage({
            id: 'reports.advancedSLA.table.avgQueueAnswer',
            defaultMessage: 'Average queue answer',
          }),
          avgQueueLoss: intl.formatMessage({
            id: 'reports.advancedSLA.table.avgQueueLoss',
            defaultMessage: 'Average queue loss',
          }),
          avgCallProcessing: intl.formatMessage({
            id: 'reports.advancedSLA.table.avgCallProcessing',
            defaultMessage: 'Average call processing',
          }),
        },
        history: {
          showConsider: intl.formatMessage({
            id: 'report.history.consider',
            defaultMessage: 'Show consider',
          }),
          showLost: intl.formatMessage({
            id: 'reports.history.showLost',
            defaultMessage: 'Show lost',
          }),
          showAdditionalField: intl.formatMessage({
            id: 'reports.history.showAdditionalFields',
            defaultMessage: 'Show addition field',
          }),
          showAppWithoutCall: intl.formatMessage({
            id: 'reports.history.showAppWithoutCall',
            defaultMessage: 'Show application without call',
          }),
          showFromEmail: intl.formatMessage({
            id: 'reports.history.showFromEmail',
            defaultMessage: 'Show application from Email',
          }),
          column: {
            createdAt: intl.formatMessage({
              id: 'reports.history.column.createdAt',
              defaultMessage: 'Created At',
            }),
            callDuration: intl.formatMessage({
              id: 'reports.history.column.callDuration',
              defaultMessage: 'Call duration',
            }),
            appId: intl.formatMessage({
              id: 'reports.history.column.appId',
              defaultMessage: 'Application Id',
            }),
            topic: intl.formatMessage({
              id: 'reports.history.column.topics',
              defaultMessage: 'Topic',
            }),
            subscriber: intl.formatMessage({
              id: 'reports.history.column.subscriber',
              defaultMessage: 'Client name',
            }),
            clientAddress: intl.formatMessage({
              id: 'reports.history.column.clientAddress',
              defaultMessage: 'Client address',
            }),
            callCreatedAt: intl.formatMessage({
              id: 'reports.history.column.callCreatedAt',
              defaultMessage: 'Call created at',
            }),
            callConnectedAt: intl.formatMessage({
              id: 'reports.history.column.callConnectedAt',
              defaultMessage: 'Call connected at',
            }),
            callDisconnectedAt: intl.formatMessage({
              id: 'reports.history.column.callDisconnectedAt',
              defaultMessage: 'Call disconnected at',
            }),
          },
          mailForm: {
            title: intl.formatMessage({
              id: 'email.form.title',
              defaultMessage: 'Email send form',
            }),
            from: intl.formatMessage({
              id: 'email.form.from',
              defaultMessage: 'From email',
            }),
            to: intl.formatMessage({
              id: 'email.form.to',
              defaultMessage: 'To email',
            }),
            topic: intl.formatMessage({
              id: 'email.form.topic',
              defaultMessage: 'Email topic',
            }),
            text: intl.formatMessage({
              id: 'email.form.text',
              defaultMessage: 'Email text',
            }),
            send: intl.formatMessage({
              id: 'email.form.send',
              defaultMessage: 'Send email',
            }),
            close: intl.formatMessage({
              id: 'email.form.close',
              defaultMessage: 'Close form',
            }),
            sended: (emailTo: string) =>
              intl.formatMessage(
                {
                  id: 'email.sended',
                  defaultMessage: 'Email is sended',
                },

                {emailTo},
              ),
            sendError: (emailTo: string) =>
              intl.formatMessage(
                {
                  id: 'email.send.error',
                  defaultMessage: 'Error send email',
                },
                {emailTo},
              ),
          },
        },
        mails: {
          title: intl.formatMessage({
            id: 'reports.mails.title',
            defaultMessage: 'MailsList title',
          }),
          createdAt: intl.formatMessage({
            id: 'reports.mails.receivingTime',
            defaultMessage: 'Receive time',
          }),
          responseTime: intl.formatMessage({
            id: 'reports.mails.responseTime',
            defaultMessage: 'Response time',
          }),
          responseTime2: intl.formatMessage({
            id: 'reports.mails.responseTime2',
            defaultMessage: 'Time on response',
          }),
          appealContent: intl.formatMessage({
            id: 'reports.mails.appealContent',
            defaultMessage: 'Appeal content',
          }),
          note: intl.formatMessage({
            id: 'reports.mails.note',
            defaultMessage: 'Note',
          }),
          unknownClientEmail: intl.formatMessage({
            id: 'reports.mails.unknownClientEmail',
            defaultMessage: 'Email',
          }),
          known: intl.formatMessage({
            id: 'reports.mails.known',
            defaultMessage: 'Known',
          }),
          deleteAgree: intl.formatMessage({
            id: 'reports.mails.delete.agree',
            defaultMessage: 'Do you agree to delete email',
          }),
          delete: intl.formatMessage({
            id: 'reports.mails.delete',
            defaultMessage: 'delete email',
          }),
        },
        nercSla: {
          appealsReceivedByPhoneToCallCenterNumbers: intl.formatMessage({
            id: 'reports.nercSla.appealsReceivedByPhoneToCallCenterNumbers',
            defaultMessage:
              'I. Appeals received by phone to call center numbers',
          }),
          applicationsReceivedThroughOtherMeansOfTelecommunications:
            intl.formatMessage({
              id: 'reports.nercSla.applicationsReceivedThroughOtherMeansOfTelecommunications',
              defaultMessage:
                'II. Applications received through other means of telecommunications',
            }),
          callFields: {
            received: intl.formatMessage({
              id: 'reports.nercSla.callFields.received',
              defaultMessage:
                'Total number of incoming calls (calls from subscribers)',
            }),
            connected: intl.formatMessage({
              id: 'reports.nercSla.callFields.connected',
              defaultMessage:
                'The number of incoming calls answered by the operator',
            }),
            ivr: intl.formatMessage({
              id: 'reports.nercSla.callFields.ivr',
              defaultMessage: 'The number of calls processed in IVR menu',
            }),
            thirtySeconds: intl.formatMessage({
              id: 'reports.nercSla.callFields.thirtySeconds',
              defaultMessage: 'Service level within 30 seconds',
            }),
            sixtySeconds: intl.formatMessage({
              id: 'reports.nercSla.callFields.sixtySeconds',
              defaultMessage: 'Service level within 60 seconds',
            }),
            lost: intl.formatMessage({
              id: 'reports.nercSla.callFields.lost',
              defaultMessage: 'The percentage of calls lost in the queue',
            }),
            avgResponse: intl.formatMessage({
              id: 'reports.nercSla.callFields.avgResponse',
              defaultMessage:
                "The average time from the start of the call to the operator's response",
            }),
            avgQueueAnswer: intl.formatMessage({
              id: 'reports.nercSla.callFields.avgQueueAnswer',
              defaultMessage: 'Average time in call queue',
            }),
            avgQueueLoss: intl.formatMessage({
              id: 'reports.nercSla.callFields.avgQueueLoss',
              defaultMessage:
                'Average waiting time in cases where the subscriber did not wait for the operator to answer',
            }),
            avgCallProcessing: intl.formatMessage({
              id: 'reports.nercSla.callFields.avgCallProcessing',
              defaultMessage: 'Average call processing time by an operator',
            }),
            avgCallsPerOperator: intl.formatMessage({
              id: 'reports.nercSla.callFields.avgCallsPerOperator',
              defaultMessage: 'Average number of calls handled by one operator',
            }),
          },
          emailFields: {
            emailCount: intl.formatMessage({
              id: 'reports.nercSla.emailFields.emailCount',
              defaultMessage: 'Number of e-mails',
            }),
            emailResponseCount: intl.formatMessage({
              id: 'reports.nercSla.emailFields.emailResponseCount',
              defaultMessage: 'Number of e-mails answered within thirty days',
            }),
            emailResponseTime: intl.formatMessage({
              id: 'reports.nercSla.emailFields.emailResponseTime',
              defaultMessage: 'Average response time to email messages',
            }),
          },
          fieldTypes: {
            units: intl.formatMessage({
              id: 'reports.nercSla.fieldTypes.units',
              defaultMessage: 'units',
            }),
            days: intl.formatMessage({
              id: 'reports.nercSla.fieldTypes.days',
              defaultMessage: 'days',
            }),
            second: intl.formatMessage({
              id: 'reports.nercSla.fieldTypes.second',
              defaultMessage: 'second',
            }),
            percent: intl.formatMessage({
              id: 'reports.nercSla.fieldTypes.percent',
              defaultMessage: '%',
            }),
          },
        },
      },
      //@setting
      setting: {
        uploadDB: intl.formatMessage({
          id: 'settings.uploadDB',
          defaultMessage: 'Upload DB',
        }),
        apiError: intl.formatMessage({
          id: 'invalid:api:endpoint',
          defaultMessage: 'Invalid API endpoint',
        }),
        general: {
          controlMode: intl.formatMessage({
            id: 'settings.general.controlMode',
            defaultMessage: 'Control mode',
          }),
          showPendingApplications: intl.formatMessage({
            id: 'settings.general.showPendingApplications',
            defaultMessage:
              'Show pending applications after operator connection',
          }),
          highlightBlacklistClient: intl.formatMessage({
            id: 'settings.general.highlightBlacklistClient',
            defaultMessage: 'Highlight blacklisted clients',
          }),
          hour: intl.formatMessage({
            id: 'settings.general.hours',
            defaultMessage: 'h',
          }),
          ms: intl.formatMessage({
            id: 'settings.general.ms',
            defaultMessage: 'ms',
          }),
          workShiftHours: (measure: string) =>
            intl.formatMessage(
              {
                id: 'settings.general.workShiftHours',
                defaultMessage: 'Work shift hours ({measure})',
              },
              {
                measure,
              },
            ),
          postProcessing: (measure: string) =>
            intl.formatMessage(
              {
                id: 'settings.general.postProcessing',
                defaultMessage: 'Post processing ({measure})',
              },
              {
                measure,
              },
            ),
          timeOfRejectStatus: (measure: string) =>
            intl.formatMessage(
              {
                id: 'settings.general.timeToReject',
                defaultMessage: 'Time from reject ({measure})',
              },
              {
                measure,
              },
            ),
          indicatorsSubmissionDateStart: intl.formatMessage({
            id: 'settings.general.indicatorsSubmissionDates.start',
            defaultMessage: 'Start date',
          }),
          indicatorsSubmissionDateEnd: intl.formatMessage({
            id: 'settings.general.indicatorsSubmissionDates.end',
            defaultMessage: 'End date',
          }),
          indicatorSubmission: intl.formatMessage({
            id: 'settings.general.indicatorSubmission',
            defaultMessage: 'Indicator submission',
          }),
          referenceIndicator: intl.formatMessage({
            id: 'settings.general.referenceIndicator',
            defaultMessage: 'General setting reference indicator',
          }),
          callPrioritySettingText: intl.formatMessage({
            id: 'settings.general.callPrioritySettingText',
            defaultMessage: 'Call priority setting text',
          }),
          callPriority: intl.formatMessage({
            id: 'settings.general.callPriority',
            defaultMessage: 'Call priority',
          }),
          callPrioritySettingTextLabel: intl.formatMessage({
            id: 'settings.general.callPrioritySettingText.label',
            defaultMessage: 'Call priority setting text label',
          }),
          showPriorityInRingingCall: intl.formatMessage({
            id: 'settings.general.showPriorityInRingingCalls',
            defaultMessage: 'Show priority in ringing call',
          }),
          settingCalls: intl.formatMessage({
            id: 'settings.general.settingCalls',
            defaultMessage: 'Setting calls',
          }),
          error: intl.formatMessage({
            id: 'settings.general.notify.error',
            defaultMessage: 'Error',
          }),
          errorAstorClientSync: intl.formatMessage({
            id: 'settings.general.syncAstorClient.error',
            defaultMessage: 'Not sync Client with Astor',
          }),
          errorApplicationSendToAstor: intl.formatMessage({
            id: 'settings.general.ApplicationSentAstor.error',
            defaultMessage: 'Application was sent',
          }),
          errorAstorDnoSync: intl.formatMessage({
            id: 'settings.general.syncAstorDno.error',
            defaultMessage: 'Not sync DNO with Astor',
          }),
          errorAstorClientDrop: intl.formatMessage({
            id: 'settings.general.dropAstorClient.error',
            defaultMessage: 'Not drop Client with Astor',
          }),
          errorAstorDnoDrop: intl.formatMessage({
            id: 'settings.general.dropAstorDno.error',
            defaultMessage: 'Not drop DNO with Astor',
          }),
          billingAuth: intl.formatMessage({
            id: 'billing.auth',
            defaultMessage: 'Billing authorization',
          }),
          billingAuthSuccess: intl.formatMessage({
            id: 'billing.auth.success',
            defaultMessage: 'Billing authorization is successful',
          }),
          billingAuthError: intl.formatMessage({
            id: 'billing.auth.error',
            defaultMessage: 'Billing authorization failed',
          }),
        },
        showDeactivatedUsers: intl.formatMessage({
          id: 'settings.users.showDeactivateUsers',
          defaultMessage: 'Show deactivated users',
        }),
        workingTime: {
          noHours: intl.formatMessage({
            id: 'settings.workingTime.timeEditor.noHours',
            defaultMessage: 'No hours',
          }),
          calender: intl.formatMessage({
            id: 'settings.workingTime.calendar',
            defaultMessage: 'Calendar',
          }),
          weekSchedule: intl.formatMessage({
            id: 'settings.workingTime.weekSchedule',
            defaultMessage: 'Schedule',
          }),
          schedules: intl.formatMessage({
            id: 'settings.workingTime.schedules',
            defaultMessage: 'Schedules',
          }),
          schedulesType: intl.formatMessage({
            id: 'settings.workingTime.schedules.type',
            defaultMessage: 'Chose schedules type',
          }),
          schedulesCommon: intl.formatMessage({
            id: 'settings.workingTime.schedules.type.common',
            defaultMessage: 'Create new common schedule',
          }),
          schedulesFullDay: intl.formatMessage({
            id: 'settings.workingTime.schedules.type.fullDay',
            defaultMessage: 'Create new full-day schedule',
          }),
          weekdays: intl.formatMessage({
            id: 'settings.workingTime.weekdays',
            defaultMessage: 'Weekdays',
          }),
          workingHours: intl.formatMessage({
            id: 'settings.workingTime.workingHours',
            defaultMessage: 'Working hours',
          }),
          createDialogTitle: intl.formatMessage({
            id: 'settings.workingTime.createDialogTitle',
            defaultMessage: 'Create new schedule',
          }),
          createDialogContent: intl.formatMessage({
            id: 'settings.workingTime.createDialogContent',
            defaultMessage:
              'Creating new schedule might be necessary when the management decided to change working hours for some reason.',
          }),
        },
        sounds: {
          sounds: intl.formatMessage({
            id: 'settings.sounds.sounds',
            defaultMessage: 'Sounds',
          }),
          music: intl.formatMessage({
            id: 'settings.sounds.moh',
            defaultMessage: 'Music on hold',
          }),
          digits: intl.formatMessage({
            id: 'settings.sounds.digits',
            defaultMessage: 'Digits',
          }),
          libraryRecordings: intl.formatMessage({
            id: 'settings.sounds.libraryRecordings',
            defaultMessage: 'Library(Recordings)',
          }),
        },
        routes: {
          newName: intl.formatMessage({
            id: 'settings.routes.newName',
            defaultMessage: 'New name',
          }),
          createRouterTitle: intl.formatMessage({
            id: 'settings.routes.createRouteTitle',
            defaultMessage: 'Create router',
          }),
          createRouterContent: intl.formatMessage({
            id: 'settings.routes.createRouteContent',
            defaultMessage:
              'Creating new routes allow you to develop and testing them without changing the main route, so decrease any downtime. Just add a new rule for some test number and this new route in rules tab.',
          }),
          routeIsUse: intl.formatMessage({
            id: 'settings.routes.routeIsUsed',
            defaultMessage: 'Route is used and cannot be removed',
          }),
        },
        rules: {
          route: intl.formatMessage({
            id: 'settings.rules.route',
            defaultMessage: 'Route',
          }),
          create: intl.formatMessage({
            id: 'settings.rules.create.title',
            defaultMessage: 'Create rules',
          }),
          edit: intl.formatMessage({
            id: 'settings.rules.edit.title',
            defaultMessage: 'Edit rules',
          }),
        },
        textToSpeechSite: {
          textToSpeech: intl.formatMessage({
            id: 'text.to.speech',
            defaultMessage: 'Convert text to speech',
          }),
          maxTextLength: intl.formatMessage({
            id: 'max.text.length',
            defaultMessage:
              'The maximum text length is no more than 256 characters including spaces',
          }),
        },
      },
      //@roles
      roles: {
        permission: intl.formatMessage({
          id: 'roles.permissions',
          defaultMessage: 'Permissions',
        }),
        create: intl.formatMessage({
          id: 'roles.create.title',
          defaultMessage: 'Create new role',
        }),
        edit: intl.formatMessage({
          id: 'roles.edit.title',
          defaultMessage: 'Edit role',
        }),
      },
      //@topic
      topics: {
        classifier: intl.formatMessage({
          id: 'topics.createDialog.classifier',
          defaultMessage: 'Classifier',
        }),
        createDialog: intl.formatMessage({
          id: 'topics.createDialog.title',
          defaultMessage: 'Create new classifier',
        }),
        createDialogDescription: intl.formatMessage({
          id: 'topics.createDialog.description',
          defaultMessage:
            'To create a new classifier you have to specify the date when it becomes active from',
        }),
        copyClassifier: intl.formatMessage({
          id: 'topics.createDialog.copy',
          defaultMessage: 'Copy existing classified',
        }),
        removeClassifier: intl.formatMessage({
          id: 'topics.removeClassifier',
          defaultMessage: 'Remove classifier',
        }),
        hasResult: intl.formatMessage({
          id: 'topics.hasResult',
          defaultMessage: 'Has result',
        }),
        request: intl.formatMessage({
          id: 'topics.request',
          defaultMessage: 'Request',
        }),
        response: intl.formatMessage({
          id: 'topics.response',
          defaultMessage: 'Response',
        }),
        isLeaf: intl.formatMessage({
          id: 'topics.isLeaf',
          defaultMessage: 'Is leaf in report',
        }),
        topicsName: intl.formatMessage({
          id: 'topics.name',
          defaultMessage: 'Theme name',
        }),
        topicCreateDialogTitle: intl.formatMessage({
          id: 'topics.topicCreate.dialog.title',
          defaultMessage: 'Create new theme',
        }),
        topicCreateCancel: intl.formatMessage({
          id: 'topics.topicCreate.cancel',
          defaultMessage: 'Cancel',
        }),
        topicCreateConfirm: intl.formatMessage({
          id: 'topics.topicCreate.create',
          defaultMessage: 'Create theme',
        }),
        topicNameFormat: intl.formatMessage({
          id: 'topics.topicName.format',
          defaultMessage:
            'T, or T{theme number}, or T{theme number}.{sub theme number}',
        }),
        topicCreateSuccess: intl.formatMessage({
          id: 'topics.topicCreate.success',
          defaultMessage: 'Theme successfully created!',
        }),
        topicDeleteSuccess: intl.formatMessage({
          id: 'topics.topicDelete.success',
          defaultMessage: 'Theme successfully deleted!',
        }),
        topicEditSuccess: intl.formatMessage({
          id: 'topics.topicEdit.success',
          defaultMessage: 'Theme successfully edited!',
        }),
      },
      //@routeEditor
      routeEditor: {
        chooseNode: intl.formatMessage({
          id: 'routeEditor.chooseNode',
          defaultMessage: 'Choose node to see its properties',
        }),
        notEditable: intl.formatMessage({
          id: 'routeEditor.notEditable',
          defaultMessage: 'This node is not editable',
        }),
        label: intl.formatMessage({
          id: 'routeEditor.editor.label',
          defaultMessage: 'Label',
        }),
        sound: intl.formatMessage({
          id: 'routeEditor.editor.sound',
          defaultMessage: 'Sound',
        }),
        recording: intl.formatMessage({
          id: 'routeEditor.editor.recording',
          defaultMessage: 'Recording',
        }),
        variable: intl.formatMessage({
          id: 'routeEditor.editor.variable',
          defaultMessage: 'Variable',
        }),
        text: intl.formatMessage({
          id: 'routeEditor.editor.text',
          defaultMessage: 'Text',
        }),
        nodeType: intl.formatMessage({
          id: 'routeEditor.node.type',
          defaultMessage: 'Node type',
        }),
        typePlaceholder: intl.formatMessage({
          id: 'routeEditor.node.type.placeholder',
          defaultMessage: 'Chose type',
        }),
        nodeLabel: intl.formatMessage({
          id: 'routeEditor.node.label',
          defaultMessage: 'Label',
        }),
      },
      //@realtime
      realtime: {
        column: {
          state: intl.formatMessage({
            id: 'realtime.column.state',
            defaultMessage: 'State',
          }),
          direction: intl.formatMessage({
            id: 'label.direction',
            defaultMessage: 'Direction',
          }),
          startedAt: intl.formatMessage({
            id: 'realtime.column.startedAt',
            defaultMessage: 'Started at',
          }),
          timeInState: intl.formatMessage({
            id: 'realtime.column.timeInState',
            defaultMessage: 'In state',
          }),
          operator: intl.formatMessage({
            id: 'label.operator',
            defaultMessage: 'Operator',
          }),
          number: intl.formatMessage({
            id: 'label.number',
            defaultMessage: 'Number',
          }),
          clientName: intl.formatMessage({
            id: 'label.clientName',
            defaultMessage: 'Client name',
          }),
          clientAddress: intl.formatMessage({
            id: 'realtime.column.clientAddress',
            defaultMessage: 'Address',
          }),
          actions: intl.formatMessage({
            id: 'actions',
            defaultMessage: 'Actions',
          }),
        },
        header: {
          calls: (length: number) =>
            intl.formatMessage(
              {
                id: 'realtime.header.calls',
                defaultMessage: 'Connected users — {length}',
              },
              {length},
            ),
          connectedUser: (length: number) =>
            intl.formatMessage(
              {
                id: 'realtime.header.connectedUsers',
                defaultMessage: 'In system - {length}',
              },
              {
                length,
              },
            ),
          disconnectedUser: (length: number) =>
            intl.formatMessage(
              {
                id: 'realtime.header.disconnectedUsers',
                defaultMessage: 'Disconnected - {length}',
              },
              {length},
            ),
          inLineUser: (length: number) =>
            intl.formatMessage(
              {
                id: 'realtime.header.disconnectedUsers',
                defaultMessage: 'Disconnected - {length}',
              },
              {length},
            ),
        },
      },
      //@testing
      testing: {
        phone: intl.formatMessage({
          id: 'testing.phone',
          defaultMessage: 'Testing phone',
        }),
        phoneDesc: intl.formatMessage({
          id: 'testing.phone.desc',
          defaultMessage:
            'Testing phone can be used to make a call as a single client using your microphone to talk with the operator',
        }),
        queue: intl.formatMessage({
          id: 'testing.queue',
          defaultMessage: 'Testing queue',
        }),
        queueDesc: intl.formatMessage({
          id: 'testing.queue.desc',
          defaultMessage:
            'This page helps to run many calls in parallel to test queue processing',
        }),
        account: intl.formatMessage({
          id: 'testing.accounts',
          defaultMessage: 'Testing account',
        }),
      },
      //@chat
      chat: {
        loadingArchived: intl.formatMessage({
          id: 'chat.loading.archived.messages',
          defaultMessage: 'Loading archived messages',
        }),
        messageDeleted: intl.formatMessage({
          id: 'message.deleted',
          defaultMessage: 'Message deleted',
        }),
        you: intl.formatMessage({
          id: 'you',
          defaultMessage: 'You',
        }),
        chatDelete: intl.formatMessage({
          id: 'chat.delete',
          defaultMessage: 'Delete chat',
        }),
        confirmChatDeletion: intl.formatMessage({
          id: 'chat.confirm.chat.deletion',
          defaultMessage: 'Are you sure you want to delete this chat?',
        }),
        deleteChatWithName: (name: string) =>
          intl.formatMessage(
            {
              id: 'chat.deleteChat.with.name',
              defaultMessage: 'Delete chat with name {name}?',
            },
            {
              name: <strong>{name}</strong>,
            },
          ),
        participantsLess4: intl.formatMessage({
          id: 'chat.participants.less.4',
          defaultMessage: 'Participants',
        }),
        participantsMore4: intl.formatMessage({
          id: 'chat.participants.more.4',
          defaultMessage: 'Participants',
        }),
        close: intl.formatMessage({
          id: 'chat.close',
          defaultMessage: 'Close chat',
        }),
        message: intl.formatMessage({
          id: 'chat.message',
          defaultMessage: 'Message',
        }),
        deleteMessage: intl.formatMessage({
          id: 'actin.deleteMessage',
          defaultMessage: 'Delete message',
        }),
        send: intl.formatMessage({
          id: 'action.send',
          defaultMessage: 'Send',
        }),
        chooseChatLabel: intl.formatMessage({
          id: 'choose.chat.label',
          defaultMessage: 'Chats',
        }),
        makeChat: intl.formatMessage({
          id: 'chat.makeChat',
          defaultMessage: 'Make chat',
        }),
        newChat: intl.formatMessage({
          id: 'chat.newChat',
          defaultMessage: 'New chat',
        }),
        makingChat: intl.formatMessage({
          id: 'chat.makingChat',
          defaultMessage: 'Making chat',
        }),
        chatName: intl.formatMessage({
          id: 'chat.Name',
          defaultMessage: 'Chat name',
        }),
        addOperators: intl.formatMessage({
          id: 'chat.addOperators',
          defaultMessage: 'Add to chat',
        }),
        unknownAuthor: intl.formatMessage({
          id: 'author.unknown',
          defaultMessage: 'Unknown author',
        }),
        createNewChat: intl.formatMessage({
          id: 'chat.createNewChat',
          defaultMessage: 'Create new chat',
        }),
        chooseChat: intl.formatMessage({
          id: 'chat.choseChat',
          defaultMessage: 'Chose chat',
        }),
      },
      //@handbooks
      handbooks: {
        externalPhoneNumbers: {
          create: intl.formatMessage({
            id: 'handbooks.externalPhoneNumbers.create',
            defaultMessage: 'Create external phone number',
          }),
          edit: intl.formatMessage({
            id: 'handbooks.externalPhoneNumbers.edit',
            defaultMessage: 'Edit external phone number',
          }),
        },
        clients: {
          list: intl.formatMessage({
            id: 'clients.list',
            defaultMessage: 'Clients list',
          }),
        },
      },
      //@dispatcher module
      dispatcherModule: {
        table: intl.formatMessage({
          id: 'dispatcherModule.table.title',
          defaultMessage: 'Dispatcher table',
        }),
      },
      //@columns' headers
      columnsHeaders: {
        //@callList columns' headers
        callList: {
          rate: intl.formatMessage({
            id: 'label.rate',
            defaultMessage: 'Rate',
          }),
          timeOfProcessing: intl.formatMessage({
            id: 'reports.history.column.callCreatedAt',
            defaultMessage: 'Call created at',
          }),
          timeOfCallEnd: intl.formatMessage({
            id: 'reports.history.column.callDisconnectedAt',
            defaultMessage: 'Call disconnected at',
          }),
          callDuration: intl.formatMessage({
            id: 'reports.history.column.callDuration',
            defaultMessage: 'Call duration',
          }),
          phoneNumber: intl.formatMessage({
            id: 'label.phoneNumber',
            defaultMessage: 'Phone number',
          }),
          processedBy: intl.formatMessage({
            id: 'label.operator',
            defaultMessage: 'Operator',
          }),
        },

        //@blacklist columns' headers
        blacklist: {
          number: intl.formatMessage({
            id: 'label.number',
            defaultMessage: 'Number',
          }),
          reason: intl.formatMessage({
            id: 'label.comment',
            defaultMessage: 'Comment',
          }),
          addedUser: intl.formatMessage({
            id: 'blacklist.added.by',
            defaultMessage: 'Added',
          }),
          createdAt: intl.formatMessage({
            id: 'blacklist.createdAt',
            defaultMessage: 'Date',
          }),
        },

        //@client indicators columns' headers
        clientIndicators: {
          date: intl.formatMessage({
            id: 'indicator.date',
            defaultMessage: 'Date',
          }),
          indicator: intl.formatMessage({
            id: 'indicator.indicator',
            defaultMessage: 'Indicator',
          }),
          dateFrom: intl.formatMessage({
            id: 'indicator.dateFrom',
            defaultMessage: 'Date from ',
          }),
          dateTo: intl.formatMessage({
            id: 'indicator.dateTo',
            defaultMessage: 'Date to',
          }),
          valueIndicator: intl.formatMessage({
            id: 'indicator.value',
            defaultMessage: 'Value indicator',
          }),
          noteToIndicator: intl.formatMessage({
            id: 'indicator.note',
            defaultMessage: 'Note to indicator',
          }),
          employeeRecordedIndicator: intl.formatMessage({
            id: 'indicator.employer',
            defaultMessage: 'The employee recorded the indicator',
          }),
        },

        //@dispatcher table columns' headers
        dispatcherTable: {
          name: intl.formatMessage({
            id: 'label.name',
            defaultMessage: 'Name',
          }),
          accident: intl.formatMessage({
            id: 'stats.table.accident',
            defaultMessage: 'Accident',
          }),
          accidentActual: intl.formatMessage({
            id: 'stats.table.isActual',
            defaultMessage: 'Actual',
          }),
          typeOfPowerOutage: intl.formatMessage({
            id: 'stats.table.typeOfPowerOutage',
            defaultMessage: 'Type Of Power Outage',
          }),
          reasonOfAccident: intl.formatMessage({
            id: 'stats.table.reasonOfAccident',
            defaultMessage: 'Reason Of Accident',
          }),
          comment: intl.formatMessage({
            id: 'stats.table.comment',
            defaultMessage: 'Comment to Accident',
          }),
          startOfAccident: intl.formatMessage({
            id: 'stats.table.startOfAccident',
            defaultMessage: 'Start of accident',
          }),
          endOfAccident: intl.formatMessage({
            id: 'stats.table.endOfAccident',
            defaultMessage: 'End of Accident',
          }),
        },

        clientAccidentTable: {
          name: intl.formatMessage({
            id: 'accident.client.name',
            defaultMessage: 'Client name',
          }),
          rem: intl.formatMessage({
            id: 'accident.client.rem',
            defaultMessage: 'REM',
          }),
          accountNumber: intl.formatMessage({
            id: 'accident.client.accountNumber',
            defaultMessage: 'Account Number',
          }),
          astorDnoId: intl.formatMessage({
            id: 'accident.client.astorDnoId',
            defaultMessage: 'DNO id',
          }),
          region: intl.formatMessage({
            id: 'accident.client.region',
            defaultMessage: 'Region',
          }),
          community: intl.formatMessage({
            id: 'accident.client.community',
            defaultMessage: 'Community',
          }),
          city: intl.formatMessage({
            id: 'accident.client.city',
            defaultMessage: 'City',
          }),
          street: intl.formatMessage({
            id: 'accident.client.street',
            defaultMessage: 'Street',
          }),
          building: intl.formatMessage({
            id: 'accident.client.building',
            defaultMessage: 'Building',
          }),
          apartment: intl.formatMessage({
            id: 'accident.client.apartment',
            defaultMessage: 'Apartment',
          }),
        },
        //@application details table columns' headers
        applicationDetails: {
          address: intl.formatMessage({
            id: 'appeal.user.address',
            defaultMessage: 'Address',
          }),
          clientName: intl.formatMessage({
            id: 'appeal.application.client.name',
            defaultMessage: 'Client Name',
          }),
          clientPhone: intl.formatMessage({
            id: 'label.phoneNumber',
            defaultMessage: 'Client phone number',
          }),
          clientAccountNo: intl.formatMessage({
            id: 'applications.new.accountNo',
            defaultMessage: 'Client account number',
          }),
          topicName: intl.formatMessage({
            id: 'label.topic.filter',
            defaultMessage: 'Topic name',
          }),
          createdAt: intl.formatMessage({
            id: 'dispatcherModule.table.subtable.createdAt',
            defaultMessage: 'Created at',
          }),
          operatorName: intl.formatMessage({
            id: 'label.operator',
            defaultMessage: 'Operator name',
          }),
        },

        //@application history table columns' headers
        applicationHistory: {
          direction: intl.formatMessage({
            id: 'label.direction',
            defaultMessage: 'Direction',
          }),
          operator: intl.formatMessage({
            id: 'label.operator',
            defaultMessage: 'Operator',
          }),
          rate: intl.formatMessage({
            id: 'label.rate',
            defaultMessage: 'Rate',
          }),
          timeOfCreation: intl.formatMessage({
            id: 'reports.history.column.createdAt',
            defaultMessage: 'Created At',
          }),
          timeOfCallCreation: intl.formatMessage({
            id: 'reports.history.column.callCreatedAt',
            defaultMessage: 'Call created at',
          }),
          timeOfProcessing: intl.formatMessage({
            id: 'reports.history.column.callConnectedAt',
            defaultMessage: 'Call connected at',
          }),
          timeOfCallEnd: intl.formatMessage({
            id: 'reports.history.column.callDisconnectedAt',
            defaultMessage: 'Call disconnected at',
          }),
          callDuration: intl.formatMessage({
            id: 'reports.history.column.callDuration',
            defaultMessage: 'Call duration',
          }),
          update: intl.formatMessage({
            id: 'reports.history.column.update.app',
            defaultMessage: 'Update time',
          }),
          phoneNumber: intl.formatMessage({
            id: 'label.phoneNumber',
            defaultMessage: 'Phone number',
          }),
          applicationId: intl.formatMessage({
            id: 'reports.history.column.appId',
            defaultMessage: 'Application Id',
          }),
          topic: intl.formatMessage({
            id: 'label.topic',
            defaultMessage: 'topic',
          }),
          client: intl.formatMessage({
            id: 'applications.client',
            defaultMessage: 'Client',
          }),
          addressOfClient: intl.formatMessage({
            id: 'reports.history.column.clientAddress',
            defaultMessage: 'Client address',
          }),
          comment: intl.formatMessage({
            id: 'label.comment',
            defaultMessage: 'Comment',
          }),
          astorComment: intl.formatMessage({
            id: 'label.astorComment',
            defaultMessage: 'Astor operator comment',
          }),
          fromEmail: intl.formatMessage({
            id: 'reports.history.column.fromEmail',
            defaultMessage: 'From email',
          }),
          emailText: intl.formatMessage({
            id: 'reports.history.column.emailText',
            defaultMessage: 'Email text',
          }),
          emailTopic: intl.formatMessage({
            id: 'email.form.topic',
            defaultMessage: 'Topic email',
          }),
          emailUserName: intl.formatMessage({
            id: 'email.user.name',
            defaultMessage: 'User name from email',
          }),
        },

        //@application history Auto table columns' headers
        applicationHistoryAuto: {
          direction: intl.formatMessage({
            id: 'label.direction',
            defaultMessage: 'Direction',
          }),
          sourceOfIncome: intl.formatMessage({
            id: 'label.sourceOfIncome',
            defaultMessage: 'Source of income',
          }),
          timeOfCreation: intl.formatMessage({
            id: 'reports.history.column.createdAt',
            defaultMessage: 'Created At',
          }),
          timeOfCallCreation: intl.formatMessage({
            id: 'reports.history.column.callCreatedAt',
            defaultMessage: 'Call created at',
          }),
          updatedAt: intl.formatMessage({
            id: 'reports.history.column.update.app',
            defaultMessage: 'Update time',
          }),
          phoneNumber: intl.formatMessage({
            id: 'label.phoneNumber',
            defaultMessage: 'Phone number',
          }),
          applicationId: intl.formatMessage({
            id: 'reports.history.column.appId',
            defaultMessage: 'Application Id',
          }),
          client: intl.formatMessage({
            id: 'applications.client',
            defaultMessage: 'Client',
          }),
          addressOfClient: intl.formatMessage({
            id: 'reports.history.column.clientAddress',
            defaultMessage: 'Client address',
          }),
          indicator: intl.formatMessage({
            id: 'label.indicator',
            defaultMessage: 'Indicator',
          }),
          note: intl.formatMessage({
            id: 'label.note',
            defaultMessage: 'Note',
          }),
          rem: intl.formatMessage({
            id: 'applications.new.rem',
            defaultMessage: 'Rem',
          }),
        },

        //@quality of supply report columns' headers
        qualityOfSupplyReport: {
          applicationId: intl.formatMessage({
            id: 'reports.qualitySupply.column.appCode',
            defaultMessage: 'App code',
          }),
          processedBy: intl.formatMessage({
            id: 'label.operator',
            defaultMessage: 'Operator',
          }),
          connectedAt: intl.formatMessage({
            id: `reports.qualitySupply.column.timeToQueen`,
            defaultMessage: 'Time to queue',
          }),
          callStart: intl.formatMessage({
            id: 'reports.qualitySupply.column.callStart',
            defaultMessage: 'Call start',
          }),
          callEnd: intl.formatMessage({
            id: 'reports.qualitySupply.column.callEnd',
            defaultMessage: 'Call end',
          }),
          fromNumber: intl.formatMessage({
            id: 'label.phoneNumber',
            defaultMessage: 'Phone number',
          }),
          topicName: intl.formatMessage({
            id: 'reports.history.column.topics',
            defaultMessage: 'Topic',
          }),
          subTopicName: intl.formatMessage({
            id: 'reports.history.column.subTopics',
            defaultMessage: 'Subtopic',
          }),
          customer: intl.formatMessage({
            id: 'label.clientName',
            defaultMessage: 'Client name',
          }),
          address: intl.formatMessage({
            id: 'reports.history.column.clientAddress',
            defaultMessage: 'Client address',
          }),
          shotContent: intl.formatMessage({
            id: 'reports.qualitySupply.column.shotContent',
            defaultMessage: 'Shot app content',
          }),
          response: intl.formatMessage({
            id: 'label.result',
            defaultMessage: 'Result',
          }),
          divisionOfNetwork: intl.formatMessage({
            id: 'reports.qualitySupply.column.divisionOfNetworks',
            defaultMessage: 'Division of network',
          }),
          placeOfConnection: intl.formatMessage({
            id: 'reports.qualitySupply.column.placeOfConnection',
            defaultMessage: 'Place of connection',
          }),
          comment: intl.formatMessage({
            id: 'label.comment',
            defaultMessage: 'Comment',
          }),
          astorOperatorComments: intl.formatMessage({
            id: 'label.astorComment',
            defaultMessage: 'Astor operator comment',
          }),
        },

        //@external phone numbers columns' headers
        externalPhoneNumbers: {
          name: intl.formatMessage({
            id: 'label.name',
            defaultMessage: 'Name',
          }),
          number: intl.formatMessage({
            id: 'label.number',
            defaultMessage: 'Number',
          }),
        },

        //@astor all clients columns' headers
        astorAllClients: {
          astorClientsId: intl.formatMessage({
            id: 'astor.clients.id',
            defaultMessage: 'ID',
          }),
          astorClientsIdDno: intl.formatMessage({
            id: 'astor.clients.idDno',
            defaultMessage: 'Id Dno client',
          }),
          astorClientsAccountNo: intl.formatMessage({
            id: 'astor.clients.accountNo',
            defaultMessage: 'Clint account number',
          }),
          astorClientsName: intl.formatMessage({
            id: 'astor.clients.name',
            defaultMessage: 'Client name',
          }),
          astorClientsAddress: intl.formatMessage({
            id: 'astor.clients.address',
            defaultMessage: 'Client address',
          }),
          astorClientsDocNumber: intl.formatMessage({
            id: 'astor.clients.docNumber',
            defaultMessage: 'Client doc number',
          }),
          astorClientsEIC: intl.formatMessage({
            id: 'astor.clients.eic',
            defaultMessage: 'Client EIC',
          }),
        },

        //@appeals report columns' headers
        appealsReport: {
          appealNumber: intl.formatMessage({
            id: 'appeal.number',
            defaultMessage: 'Number',
          }),
          appealCallCreatedAt: intl.formatMessage({
            id: 'appeal.call.createdAt',
            defaultMessage: 'Call created time',
          }),
          appealCallId: intl.formatMessage({
            id: 'appeal.call.id',
            defaultMessage: 'Call number',
          }),
          appealFullName: intl.formatMessage({
            id: 'appeal.call.processedBy.fullName',
            defaultMessage: 'Operator name',
          }),
          appealFromNumber: intl.formatMessage({
            id: 'appeal.call.fromNumber',
            defaultMessage: 'Phone number',
          }),
          appealClientName: intl.formatMessage({
            id: 'appeal.application.client.name',
            defaultMessage: 'Client phone number',
          }),
          appealUserAddress: intl.formatMessage({
            id: 'appeal.user.address',
            defaultMessage: 'User address',
          }),
          appealTopic: intl.formatMessage({
            id: 'appeal.application.topic.name',
            defaultMessage: 'Topic',
          }),
          appealComment: intl.formatMessage({
            id: 'appeal.application.comment',
            defaultMessage: 'comment',
          }),
          appealOperator: intl.formatMessage({
            id: 'appeal.consideredBy.fullName',
            defaultMessage: 'Operator',
          }),
          ConsiderationResult: intl.formatMessage({
            id: 'appeal.application.topic.response',
            defaultMessage: 'Consideration result',
          }),
          appealAnswerDate: intl.formatMessage({
            id: 'appeal.answerDate',
            defaultMessage: 'Answer date',
          }),
          appealAnswerType: intl.formatMessage({
            id: 'appeal.answerType',
            defaultMessage: 'Answer type',
          }),
          edit: intl.formatMessage({
            id: 'action.edit',
            defaultMessage: 'Edit',
          }),
        },

        //mails report columns' headers
        mailsReport: {
          id: intl.formatMessage({
            id: 'label.number',
            defaultMessage: 'Number',
          }),
          createdAt: intl.formatMessage({
            id: 'reports.mails.receivingTime',
            defaultMessage: 'Receive time',
          }),
          responseTime: intl.formatMessage({
            id: 'reports.mails.responseTime',
            defaultMessage: 'Response time',
          }),
          responseCountTime: intl.formatMessage({
            id: 'reports.mails.responseTime2',
            defaultMessage: 'Time on response',
          }),
          clientName: intl.formatMessage({
            id: 'label.username',
            defaultMessage: 'User name',
          }),
          email: intl.formatMessage({
            id: 'reports.mails.unknownClientEmail',
            defaultMessage: 'Email',
          }),
          operatorName: intl.formatMessage({
            id: 'label.operator',
            defaultMessage: 'Operator',
          }),
          phoneNumber: intl.formatMessage({
            id: 'label.phoneNumber',
            defaultMessage: 'Phone number',
          }),
          address: intl.formatMessage({
            id: 'appeal.user.address',
            defaultMessage: 'User address',
          }),
          requestData: intl.formatMessage({
            id: 'reports.mails.appealContent',
            defaultMessage: 'Appeal content',
          }),
          topicName: intl.formatMessage({
            id: 'label.topic',
            defaultMessage: 'topic',
          }),
          responseData: intl.formatMessage({
            id: 'topics.response',
            defaultMessage: 'Response',
          }),
          answerCall: intl.formatMessage({
            id: 'reports.mails.note',
            defaultMessage: 'Note',
          }),
        },

        //accident table columns' headers

        accident: {
          dateEvent: intl.formatMessage({
            id: 'date.accident',
            defaultMessage: 'Date of Accident',
          }),
          datePlanIn: intl.formatMessage({
            id: 'plan.in.accident',
            defaultMessage: 'Plan in Accident',
          }),
          powerIsRestored: intl.formatMessage({
            id: 'power.is.restored',
            defaultMessage: 'Power is restored',
          }),
          reasonOfAccident: intl.formatMessage({
            id: 'reason.of.accident',
            defaultMessage: 'Reason of Accident',
          }),
          typeOf: intl.formatMessage({
            id: 'typeof.action',
            defaultMessage: 'Type of Event',
          }),
          comment: intl.formatMessage({
            id: 'comment',
            defaultMessage: 'Comment',
          }),
          isActual: intl.formatMessage({
            id: 'is.actual',
            defaultMessage: 'Is actual',
          }),
          dateReceivedFromAstor: intl.formatMessage({
            id: 'date.received.from.astor',
            defaultMessage: 'Date received from Astor',
          }),
        },

        //@roles columns' headers
        roles: {
          name: intl.formatMessage({
            id: 'label.name',
            defaultMessage: 'Name',
          }),
          permission: intl.formatMessage({
            id: 'roles.permissions',
            defaultMessage: 'Permissions',
          }),
        },
        //@prev apps headers
        prevApps: {
          id: intl.formatMessage({
            id: 'applications.id',
            defaultMessage: 'ID',
          }),
          fromNumber: intl.formatMessage({
            id: 'applications.phoneNumber',
            defaultMessage: 'Phone Number',
          }),
          client: intl.formatMessage({
            id: 'applications.client',
            defaultMessage: 'Client',
          }),
          indicator: intl.formatMessage({
            id: 'indicator.indicator',
            defaultMessage: 'Indicator',
          }),
          createdAt: intl.formatMessage({
            id: 'reports.history.column.callCreatedAt.DateTime',
            defaultMessage: 'Date/Time of creating call',
          }),
          billingStatus: intl.formatMessage({
            id: 'app.billingStatus',
            defaultMessage: 'Application status in Billing',
          }),
          billingCreatedAt: intl.formatMessage({
            id: 'app.billingCreating.time',
            defaultMessage: 'Billing Enrollment Time',
          }),
          sourceOfIncome: intl.formatMessage({
            id: 'label.sourceOfIncome',
            defaultMessage: 'Source of income',
          }),
        },

        //@rules columns' headers
        rules: {
          from: intl.formatMessage({
            id: 'periodSelect.from',
            defaultMessage: 'From',
          }),
          to: intl.formatMessage({
            id: 'periodSelect.to',
            defaultMessage: 'To',
          }),
          route: intl.formatMessage({
            id: 'settings.rules.route',
            defaultMessage: 'Route',
          }),
          direction: intl.formatMessage({
            id: 'label.direction',
            defaultMessage: 'Direction',
          }),
        },

        //@sip trunks columns' headers
        sipTrunks: {
          name: intl.formatMessage({
            id: 'sip-trunks.name',
            defaultMessage: 'Sip-trunks name',
          }),
          serverUrl: intl.formatMessage({
            id: 'sip-trunks.server.url',
            defaultMessage: 'Server url',
          }),
          codec: intl.formatMessage({
            id: 'sip-trunks.codec',
            defaultMessage: 'Codec',
          }),
          transport: intl.formatMessage({
            id: 'sip-trunks.transport',
            defaultMessage: 'Transport',
          }),
          fromUser: intl.formatMessage({
            id: 'sip-trunks.fromUser',
            defaultMessage: 'fromUser',
          }),
        },

        //@nerc sla columns' headers
        nercSla: {
          numeration: intl.formatMessage({
            id: 'nerc.sla.numeration',
            defaultMessage: '№',
          }),
          indicator: intl.formatMessage({
            id: 'nerc.sla.indicator',
            defaultMessage: 'Indicator',
          }),
          unitOfMeasurement: intl.formatMessage({
            id: 'nerc.sla.unitOfMeasurement',
            defaultMessage: 'Unit of measurement',
          }),
          indicatorValue: intl.formatMessage({
            id: 'nerc.sla.indicatorValue',
            defaultMessage: 'Indicator value',
          }),
        },

        //@nerc topics table columns' headers
        nercTopics: {
          topicCode: intl.formatMessage({
            id: 'nerc.topics.topicCode',
            defaultMessage: 'Topic code',
          }),
          topic: intl.formatMessage({
            id: 'nerc.topics.topic',
            defaultMessage: 'Topic',
          }),
          subTopic: intl.formatMessage({
            id: 'nerc.topics.subTopic',
            defaultMessage: 'Subtopic',
          }),
          subTopicCode: intl.formatMessage({
            id: 'nerc.topics.subTopicCode',
            defaultMessage: 'Subtopic code',
          }),
          numberOfCalls: intl.formatMessage({
            id: 'nerc.topics.numberOfCalls',
            defaultMessage: 'Number of calls',
          }),
          numberOfEmails: intl.formatMessage({
            id: 'nerc.topics.numberOfEmails',
            defaultMessage: 'Number of emails',
          }),
        },
      },
      tableHelpers: {
        accident: {
          yes: intl.formatMessage({
            id: 'yes',
            defaultMessage: 'Yes',
          }),
          no: intl.formatMessage({
            id: 'no',
            defaultMessage: 'no',
          }),
          unknownType: intl.formatMessage({
            id: 'unknown.type',
            defaultMessage: 'Unknown type',
          }),
          unknownEventStartData: intl.formatMessage({
            id: 'unknown.event.start.data',
            defaultMessage: 'Event start date unknown',
          }),
          unknownPlannedActivationDate: intl.formatMessage({
            id: 'unknown.planned.activation.date',
            defaultMessage: 'The planned activation date is unknown',
          }),
          unknownCreatedAt: intl.formatMessage({
            id: 'unknown.createdAt',
            defaultMessage: 'Unknown date of creation',
          }),
        },
      },
      module: {
        moduleList: intl.formatMessage({
          id: 'module.list',
          defaultMessage: 'Module List',
        }),
        moduleCompany: intl.formatMessage({
          id: 'module.company',
          defaultMessage: 'About company',
        }),
      },
      //@api errors
      apiErrors: {
        NEW_INDICATOR_WAS_NOT_CREATED: intl.formatMessage({
          id: 'error.notCreated.newIndicator',
          defaultMessage: 'New indicator was not created',
        }),
        ID_DNO_IS_0: intl.formatMessage({
          id: `astor.not.fount.dno`,
          defaultMessage: 'Client dont have DNO',
        }),
        APPLICATION_WAS_NOT_SAVED: intl.formatMessage({
          id: 'error.notSave.Apps',
          defaultMessage: 'Can not save applications',
        }),
        MESSAGE_NOT_SENT: intl.formatMessage({
          id: 'messageNotSent',
          defaultMessage: 'Message has not been sent',
        }),
        UNABLE_FINISH_CALL_APPLICATION_WITHOUT_DNO: intl.formatMessage({
          id: 'error.notClosed.Call',
          defaultMessage: 'Unable finish call without DNO',
        }),
        NOT_POSSIBLE_TO_CLOSE_CLIENT_APPLICATION_WITHOUT_DNO:
          intl.formatMessage({
            id: 'error.notClosed.App',
            defaultMessage:
              'Not possible to close application without client DNO',
          }),
        LEGAL_CLIENT_HAS_NO_DNO: intl.formatMessage({
          id: 'error.client.has.no.dno',
          defaultMessage: 'Legal client has no DNO',
        }),
        CAN_NOT_GET_APPLICATION: intl.formatMessage({
          id: 'error.notFound.App',
          defaultMessage: 'Can not found applications',
        }),
        APPLICATION_WAS_NOT_DELETE: intl.formatMessage({
          id: 'error.notDelete.App',
          defaultMessage: 'Can not delete applications',
        }),
        TKO_IS_REQUIRED: intl.formatMessage({
          id: 'error.application.tko',
          defaultMessage: 'Application tko is required',
        }),
        ROUTE_IS_USED_BY_A_RULE: intl.formatMessage({
          id: 'settings.routes.routeIsUsed',
          defaultMessage: 'Route is used and cannot be removed',
        }),
        CALL_APPLICATION_HAS_NO_TOPIC: intl.formatMessage({
          id: 'error.callAppTopic.isRequired',
          defaultMessage:
            'Topic application in call is required. Please take topic',
        }),
        APPLICATION_HAS_NO_TOPIC: intl.formatMessage({
          id: 'error.appTopic.isRequired',
          defaultMessage: 'Topic application is required.Please take topic',
        }),
        APP_CAN_NOT_BE_EMPTY: intl.formatMessage({
          id: 'error.application.canNotBeEmpty',
          defaultMessage: 'Application can not by empty.Please take client',
        }),
        CAN_NOT_GET_CLIENT_BINDS: intl.formatMessage({
          id: 'error.badRequest.getBindClients',
          defaultMessage: 'Can not get client bind numbers',
        }),
        CAN_NOT_GET_OBJECT_BINDS: intl.formatMessage({
          id: 'error.badRequest.getBindObjects',
          defaultMessage: 'Can not get object bind numbers',
        }),
        CLIENT_BINDS_WAS_NOT_DELETE: intl.formatMessage({
          id: 'error.badRequest.deleteBindClients',
          defaultMessage: 'Can not delete client bind numbers',
        }),
        CAN_NOT_CREATE_CLIENT_BINDS: intl.formatMessage({
          id: 'error.badRequest.postBindClients',
          defaultMessage: 'Can not bind phoneNumber tu client',
        }),
        ERROR_EDIT_ROUTE: intl.formatMessage({
          id: 'error.permission:edit:route',
          defaultMessage: 'You dont have permission edit route',
        }),
        ERROR_DELETE_ROUTE: intl.formatMessage({
          id: 'error.permission:delete:route',
          defaultMessage: 'You dont have permission delete route',
        }),
        ERROR_CREATE_RULE: intl.formatMessage({
          id: 'error.permission:create:rule',
          defaultMessage: 'You dont have permission to create rule',
        }),
        ERROR_EDIT_RULE: intl.formatMessage({
          id: 'error.permission:edit:rule',
          defaultMessage: 'You dont have permission to edit rule',
        }),
        ERROR_DELETE_RULE: intl.formatMessage({
          id: 'error.permission:delete:rule',
          defaultMessage: 'You dont have permission to delete rule',
        }),
        default: intl.formatMessage({
          id: 'error.badRequest',
          defaultMessage: 'Bad Request',
        }),
        INVALID_INTERVAL_FROM_CAN_NOT_BE_BIGGER_THAN_TO: intl.formatMessage({
          id: 'error.fromIsBiggerThanTo',
          defaultMessage: 'From is bigger than to',
        }),
        NOT_FOUND: intl.formatMessage({
          id: 'message.noData',
          defaultMessage: 'No data',
        }),
        TOPIC_IS_REQUIRED: intl.formatMessage({
          id: 'error.application.topic',
          defaultMessage: 'Application topic is required',
        }),
        INVALID_LOGIN: intl.formatMessage({
          id: 'error.notValid.mailSender.login',
          defaultMessage:
            'Wrong phone number format, it had to be +380XXXXXXXXX or 0XXXXXXXXX',
        }),
        INVALID_EMAIL: intl.formatMessage({
          id: 'error.notValid.mailSender.email',
          defaultMessage: 'Wrong email format!',
        }),
        INVALID_PASSWORD: intl.formatMessage({
          id: 'error.notValid.mailSender.password',
          defaultMessage: 'Wrong password format!',
        }),
        INVALID_EMAIL_API_KEY: intl.formatMessage({
          id: 'error.notValid.mailSender.emailApiKey',
          defaultMessage: 'Wrong email Api Key!',
        }),
        CANNOT_BE_EMPTY_FIELD: intl.formatMessage({
          id: 'error.notValid.mailSender.emptyFields',
          defaultMessage: 'Field cannot be empty!',
        }),
        SOUND_TYPE_NOT_FOUND: intl.formatMessage({
          id: 'error.notFound.soundType',
          defaultMessage: 'The specified sound type was not found',
        }),
        ONE_OR_MORE_SOUNDS_WERE_NOT_SAVED: intl.formatMessage({
          id: 'error.badRequest.oneOrMoreSoundsNotSaved',
          defaultMessage: 'One or more sounds were not saved',
        }),
        USER_NOT_FOUND_OR_INVALID_TOKEN: intl.formatMessage({
          id: 'error.userNotFoundOrInvalidToken',
          defaultMessage: 'User not found or invalid token',
        }),
        OPTIONS_NOT_FOUND: intl.formatMessage({
          id: 'error.optionsNotFound',
          defaultMessage: 'Options not found',
        }),

        //@chat errors
        'PERMISSION_DENIED:CREATE_CHAT': intl.formatMessage({
          id: 'error.permission:create:chat',
          defaultMessage: 'Permission denied to create chat',
        }),
        'PERMISSION_DENIED:DELETE_CHAT': intl.formatMessage({
          id: 'error.permission:delete:chat',
          defaultMessage: 'Permission denied to delete chat',
        }),
        'PERMISSION_DENIED:DELETE_MESSAGE': intl.formatMessage({
          id: 'error.permission:delete:message',
          defaultMessage: 'Permission denied to delete message',
        }),

        //@form errors
        PERMISSIONS_CREATE_EXTERNAL_NUMBERS: intl.formatMessage({
          id: 'error.permissions:create:externalPhoneNumbers',
          defaultMessage:
            'You dont have permission to create external phone numbers',
        }),
        PERMISSIONS_EDIT_EXTERNAL_NUMBERS: intl.formatMessage({
          id: 'error.permissions:edit:externalPhoneNumbers',
          defaultMessage:
            'You dont have permission to edit external phone numbers',
        }),
        PERMISSIONS_DELETE_EXTERNAL_NUMBERS: intl.formatMessage({
          id: 'error.permissions:delete:externalPhoneNumbers',
          defaultMessage:
            'You dont have permission to delete external phone numbers',
        }),
        PERMISSION_CREATE_BLACKLIST: intl.formatMessage({
          id: 'error.permission:create:blacklist',
          defaultMessage: 'You dont have permission to create blacklist',
        }),
        PERMISSION_EDIT_BLACKLIST: intl.formatMessage({
          id: 'error.permission:edit:blacklist',
          defaultMessage: 'You dont have permission to edit blacklist',
        }),
        PERMISSION_DELETE_BLACKLIST: intl.formatMessage({
          id: 'error.permission:delete:blacklist',
          defaultMessage: 'You dont have permission to delete blacklist',
        }),
        PERMISSION_CREATE_RULE: intl.formatMessage({
          id: 'error.permission:create:rule',
          defaultMessage: 'You dont have permission create rule',
        }),
        PERMISSION_EDIT_RULE: intl.formatMessage({
          id: 'error.permission:edit:rule',
          defaultMessage: 'You dont have permission edit rule',
        }),
        PERMISSION_DELETE_RULE: intl.formatMessage({
          id: 'error.permission:delete:rule',
          defaultMessage: 'You dont have permission delete rule',
        }),
        PERMISSION_CREATE_TRUNKS: intl.formatMessage({
          id: 'error.permission:create:trunks',
          defaultMessage: 'You dont have permission to create trunks',
        }),
        PERMISSION_EDIT_TRUNKS: intl.formatMessage({
          id: 'error.permission:edit:trunks',
          defaultMessage: 'You dont have permission to edit trunks',
        }),
        PERMISSION_DELETE_TRUNKS: intl.formatMessage({
          id: 'error.permission:delete:trunks',
          defaultMessage: 'You dont have permission to delete trunks',
        }),
        USERNAME_ALREADY_EXIST: intl.formatMessage({
          id: 'error.userNameAlreadyExist',
          defaultMessage: 'User with this username already exist',
        }),
        RULE_ALREADY_EXIST: intl.formatMessage({
          id: 'error.ruleFieldsAlreadyExist',
          defaultMessage: 'Rule with this fields already exist',
        }),
        EMAIL_ALREADY_EXIST: intl.formatMessage({
          id: 'error.emailAlreadyExist',
          defaultMessage: 'Email already exist',
        }),
        ROLE_WITH_NAME_ALREADY_EXIST: intl.formatMessage({
          id: 'error.roleWithThisNameAlreadyExist',
          defaultMessage: 'Role with this name already exist',
        }),
        PHONE_NUMBER_ALREADY_EXISTS_IN_BLACKLIST: intl.formatMessage({
          id: 'error.phoneNumberAlreadyExistsInBlacklist',
          defaultMessage: 'Phone with this number already exists',
        }),
        PHONE_NUMBER_ALREADY_EXISTS_IN_EXTERNAL_NUMBERS: intl.formatMessage({
          id: 'error.phoneNumberAlreadyExistsInExternalPhoneNumbers',
          defaultMessage:
            'This phone number already exists in external phone numbers',
        }),
        EXTERNAL_PHONE_NUMBER_DOES_NOT_EXIST: intl.formatMessage({
          id: 'error.externalPhoneNumberDoesNotExist',
          defaultMessage: 'External phone number does not exist',
        }),
        USER_WITH_THIS_USERNAME_IS_DEACTIVATED: intl.formatMessage({
          id: 'error.userWithThisUsernameIsDeactivated',
          defaultMessage: 'User with this username is deactivated',
        }),
        USER_WITH_THIS_EMAIL_IS_DEACTIVATED: intl.formatMessage({
          id: 'error.userWithThisEmailIsDeactivated',
          defaultMessage: 'User with this email is deactivated',
        }),
        CANT_UPDATE_LEGAL_CLIENT_NUMBER: intl.formatMessage({
          id: 'error.badRequest.cantUpdateLegalClientNumber',
          defaultMessage: 'Can not update client bind numbers',
        }),
        FAILED_TO_UPDATE_PHONE_NUMBER: intl.formatMessage({
          id: 'error.badRequest.failedToUpdatePhoneNumber',
          defaultMessage: 'Failed to update phone number',
        }),
        INVALID_PHONE_NUMBER_FORMAT: intl.formatMessage({
          id: 'error.badRequest.invalidPhoneNumberFormat',
          defaultMessage:
            'Invalid number format, please enter the number in the format 380XXXXXXXXXX or 0XXXXXXXXXX',
        }),
        INDICATORS_SUBMISSION_DATES_START_END: intl.formatMessage({
          id: 'error.indicatorsSubmissionDates.start.end',
          defaultMessage:
            'The start date cannot be more or equal than end date',
        }),
        INDICATORS_SUBMISSION_DATES_END_START: intl.formatMessage({
          id: 'error.indicatorsSubmissionDates.end.start',
          defaultMessage:
            'The end date cannot be less or equal than start date',
        }),

        // @Astor_sent_application
        NOT_SENT_TO_ASTOR_IN_DEVELOPMENT_MODE: intl.formatMessage({
          id: 'forbidden.sent.application',
          defaultMessage:
            'It is NOT possible to send an application to Astor in DEVELOPMENT mode',
        }),
      },
    }),
    [intl],
  );
}
