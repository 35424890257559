import {Permissions} from '@horn1/api';
import Page404 from 'components/pages/Page404';
import {lazy} from 'react';
import {Redirect} from 'react-router-dom';
import {PrivateRoute} from 'routes/models';

import privatePaths from './path';

const ScopeForm = lazy(
  () =>
    import(
      'modules/demo/crm/agreements/scopes/components/scope-form/ScopeForm'
    ),
);
const PaymentForm = lazy(
  () =>
    import(
      'modules/demo/crm/agreements/payments/components/payment-form/PaymentForm'
    ),
);
const MailSender = lazy(
  () => import('modules/settings/settings-mail-sender/MailSender'),
);
const AgreementFormPage = lazy(
  () => import('modules/demo/crm/agreements/AgreementFormPage'),
);
const Appeals = lazy(() => import('modules/reports/reports-appeals/Appeals'));
const SettingsGeneral = lazy(
  () => import('modules/settings/general-setting/SettingsGeneral'),
);
const Roles = lazy(() => import('modules/roles/Roles'));
const Topics = lazy(
  () => import('modules/settings/settings-topics/SettingsTopics'),
);
const Sounds = lazy(() => import('modules/settings/SettingsSounds'));
const Routes = lazy(() => import('modules/settings/settings-routes'));
const SettingsWorkingTime = lazy(
  () => import('modules/settings/settings-working-time/SettingsWorkingTime'),
);
const Rules = lazy(() => import('modules/rules'));
const BlackList = lazy(() => import('modules/black-list'));
const Users = lazy(() => import('modules/users'));
const SipTrunks = lazy(() => import('modules/sip-trunks'));
const Profile = lazy(() => import('modules/profile'));

const Testing = lazy(() => import('modules/Testing'));
const TestPhone = lazy(() => import('modules/TestPhone'));
const TestQueue = lazy(() => import('modules/TestQueue'));
const Call = lazy(() => import('modules/calls/call/Call'));
const CallsDashboard = lazy(() => import('modules/calls/CallsDashboard'));
const Application = lazy(() => import('modules/applications/Application'));
const NewApplication = lazy(
  () =>
    import(
      'modules/applications/components/create-application/CreateApplication'
    ),
);
const NercSlaReport = lazy(() => import('modules/nerc/NercSlaReport'));
const NercTopicsReport = lazy(() => import('modules/nerc/NercTopicsReport'));
const ProtectedReports = lazy(
  () => import('modules/nerc/protected-reports/ProtectedReportsTable'),
);
const ProtectedReportPage = lazy(
  () => import('modules/nerc/protected-reports/ProtectedReportPage'),
);
const ReportsMails = lazy(() => import('modules/reports/reports-mail'));

const ReportsHistoryByApp = lazy(
  () =>
    import(
      'modules/reports/reports-history/applications-history/ApplicationsHistory'
    ),
);

const ReportsHistoryByAppAuto = lazy(
  () =>
    import(
      'modules/reports/reports-history/applications-history-auto/ApplicationsHistoryAuto'
    ),
);

const ReportsRating = lazy(() => import('modules/reports/rating-reports'));

const ExtendedApplicationsReports = lazy(
  () => import('modules/reports/reports-advanced/reports-advanced-topic'),
);

const ReportsAdvancedSla = lazy(
  () => import('modules/reports/reports-advanced/reports-advanced-sla'),
);

const ReportsSupplyQuality = lazy(
  () => import('modules/reports/quality-of-supply-reports'),
);

const ReportsOperatorWork = lazy(
  () => import('modules/reports/reports-operator-work'),
);

const ReportsSla = lazy(() => import('modules/reports/reports-sla'));

const ToeOperatorsReports = lazy(
  () => import('modules/reports/toe-operator-reports'),
);

const ReportAstorWorkService = lazy(
  () => import('modules/reports/report-astor'),
);

const ExternalPhoneNumbers = lazy(
  () => import('modules/handbooks/external-phone-numbers'),
);

const ClientsPage = lazy(() => import('modules/handbooks/clients/Client'));

const CanBanCRM = lazy(() => import('modules/demo/crm/canban-crm/CanBanCrm'));
const ListCRM = lazy(() => import('modules/demo/crm/ListCrm'));

const Chats = lazy(() => import('modules/demo/input-chanels/Chats'));
const VideoChats = lazy(() => import('modules/demo/input-chanels/VideoChats'));
const SocialNetworks = lazy(
  () => import('modules/demo/input-chanels/SocialNetworks'),
);
const CRM = lazy(() => import('modules/demo/input-chanels/Crm'));
const Marketplace = lazy(
  () => import('modules/demo/input-chanels/Marketplace'),
);
const WebChats = lazy(() => import('modules/demo/input-chanels/WebChats'));
const Email = lazy(() => import('modules/demo/input-chanels/Email'));
const Forms = lazy(() => import('modules/demo/input-chanels/Forms'));
const CRMSiteBilder = lazy(
  () => import('modules/demo/input-chanels/CrmSiteBilder'),
);

const SMS = lazy(() => import('modules/demo/output-chanels/Sms'));
const Messengers = lazy(() => import('modules/demo/output-chanels/Messengers'));
const Callback = lazy(() => import('modules/demo/output-chanels/Callback'));
const EmailOut = lazy(() => import('modules/demo/output-chanels/Email'));

const DB = lazy(() => import('modules/demo/external-tools/Db'));
const NPSAgents = lazy(() => import('modules/demo/external-tools/NpsAgents'));
const VirtualAgents = lazy(
  () => import('modules/demo/external-tools/VirtualAgents'),
);
const SuperAgents = lazy(
  () => import('modules/demo/external-tools/SuperAgents'),
);
const AdveristingOffices = lazy(
  () => import('modules/demo/external-tools/AdveristingOffices'),
);
const ArtificialIntelligence = lazy(
  () => import('modules/demo/external-tools/ArtificialIntelligence'),
);

const TimeTracking = lazy(
  () => import('modules/demo/internal-tools/TimeTracking'),
);
const DataBase = lazy(() => import('modules/demo/internal-tools/Db'));

const Interface = lazy(() => import('modules/demo/settings/Interface'));

const DispatcherTable = lazy(
  () => import('modules/dispatcher-module/dispatcher-table/DispatcherTable'),
);

const TextToSpeech = lazy(() => import('modules/text-to-speech/TextToSpeech'));

const Module = lazy(() => import('modules/modul/Module'));
const UploadDB = lazy(() => import('modules/settings/upload-DB/UploadDb'));
const TasksReport = lazy(() => import('modules/demo/crm/report/TasksReport'));
const Agreements = lazy(() => import('modules/demo/crm/agreements/Agreements'));
const Customers = lazy(() => import('modules/demo/crm/customers/Customers'));
const ConsumerForm = lazy(
  () =>
    import('modules/demo/crm/customers/components/consumer-form/ConsumerForm'),
);

const FinancesReport = lazy(
  () => import('modules/demo/crm/finances-report/FinancesReport'),
);

const privateRoutes: PrivateRoute[] = [
  {
    path: privatePaths.financesReport,
    component: FinancesReport,
  },
  {
    path: privatePaths.consumerForm,
    component: ConsumerForm,
  },
  {
    path: privatePaths.agreementForm,
    component: AgreementFormPage,
  },
  {
    path: privatePaths.scopeForm,
    component: ScopeForm,
  },
  {
    path: privatePaths.paymentForm,
    component: PaymentForm,
  },
  {
    path: privatePaths.crmAgreements,
    component: Agreements,
  },
  {
    path: privatePaths.crmClients,
    component: Customers,
  },
  {
    path: privatePaths.tasksReport,
    component: TasksReport,
  },
  {
    path: privatePaths.uploadDB,
    component: UploadDB,
    permission: [Permissions.ReadUploadDB],
  },
  {
    path: privatePaths.textToSpeech,
    component: TextToSpeech,
    permission: [Permissions.ReadTextToSpeech],
  },
  {
    path: privatePaths.canban,
    component: CanBanCRM,
    permission: [Permissions.ReadCanban],
  },
  {
    path: privatePaths.list,
    component: ListCRM,
    permission: [Permissions.ReadList],
  },
  {
    path: privatePaths.chanelsChats,
    component: Chats,
    permission: [Permissions.ReadInputChanelsMessengers],
  },
  {
    path: privatePaths.videoChats,
    component: VideoChats,
    permission: [Permissions.ReadInputChanelsVideoMessengers],
  },
  {
    path: privatePaths.socialNetworks,
    component: SocialNetworks,
    permission: [Permissions.ReadInputChanelsSocialNetworcs],
  },
  {
    path: privatePaths.inputChanelsCRM,
    component: CRM,
    permission: [Permissions.ReadInputChanelsCRM],
  },
  {
    path: privatePaths.inputChanelsMarketplace,
    component: Marketplace,
    permission: [Permissions.ReadInputChanelsMarketplace],
  },
  {
    path: privatePaths.inputChanelsWebChats,
    component: WebChats,
    permission: [Permissions.ReadInputChanelsWebChats],
  },
  {
    path: privatePaths.inputChanelsEmail,
    component: Email,
    permission: [Permissions.ReadInputChanelsEmail],
  },
  {
    path: privatePaths.inputChanelsForms,
    component: Forms,
    permission: [Permissions.ReadInputChanelsForms],
  },
  {
    path: privatePaths.inputChanelsCRMSiteBilder,
    component: CRMSiteBilder,
    permission: [Permissions.ReadInputChanelsCRMSiteBilder],
  },
  {
    path: privatePaths.outputChanelsSms,
    component: SMS,
    permission: [Permissions.ReadOutputChanelsSms],
  },
  {
    path: privatePaths.outputChanelsMessengers,
    component: Messengers,
    permission: [Permissions.ReadOutputChanelsMessengers],
  },
  {
    path: privatePaths.outputChanelsCallback,
    component: Callback,
    permission: [Permissions.ReadOutputChanelsCallback],
  },
  {
    path: privatePaths.outputChanelsEmail,
    component: EmailOut,
    permission: [Permissions.ReadOutputChanelsEmail],
  },
  {
    path: privatePaths.externalToolsDB,
    component: DB,
    permission: [Permissions.ReadExternalToolsDB],
  },
  {
    path: privatePaths.externalToolsNPSAgents,
    component: NPSAgents,
    permission: [Permissions.ReadExternalToolsNPSAgents],
  },
  {
    path: privatePaths.externalToolsVirtualAgents,
    component: VirtualAgents,
    permission: [Permissions.ReadExternalToolsVirtualAgents],
  },
  {
    path: privatePaths.externalToolsSuperAgents,
    component: SuperAgents,
    permission: [Permissions.ReadExternalToolsSuperAgents],
  },
  {
    path: privatePaths.externalToolsAdveristingOffices,
    component: AdveristingOffices,
    permission: [Permissions.ReadExternalToolsAdveristingOffices],
  },
  {
    path: privatePaths.externalToolsAI,
    component: ArtificialIntelligence,
    permission: [Permissions.ReadExternalToolsAI],
  },
  {
    path: privatePaths.internalToolsTimeTracking,
    component: TimeTracking,
    permission: [Permissions.ReadInternalToolsTimeTracking],
  },
  {
    path: privatePaths.internalToolsDataBase,
    component: DataBase,
    permission: [Permissions.ReadInternalToolsDataBase],
  },
  {
    path: privatePaths.settingsInterface,
    component: Interface,
    permission: [Permissions.ReadsettingsInterface],
  },
  {
    path: privatePaths.callsDashboard,
    component: CallsDashboard,
    exact: true,
  },
  {
    path: privatePaths.call,
    component: Call,
  },
  {
    path: '/',
    component: () => <Redirect to={privatePaths.callsDashboard} />,
    exact: true,
  },
  {
    path: privatePaths.application,
    component: Application,
    exact: true,
  },
  {
    path: privatePaths.newApplication,
    component: NewApplication,
    exact: true,
  },
  {
    path: privatePaths.reportAstorWorkService,
    component: ReportAstorWorkService,
    exact: true,
  },
  {
    path: privatePaths.protectedReports,
    component: ProtectedReports,
    exact: true,
  },
  {
    path: privatePaths.protectedReportsId,
    component: ProtectedReportPage,
    permission: [Permissions.ReadNercTopicsReport],
  },
  {
    path: privatePaths.reportsHistory,
    component: ReportsHistoryByApp,
    permission: [Permissions.ViewHistoryReport],
  },
  {
    path: privatePaths.reportsHistoryAuto,
    component: ReportsHistoryByAppAuto,
    permission: [Permissions.ViewHistoryAutoReport],
  },
  {
    path: privatePaths.reportsRating,
    component: ReportsRating,
    permission: [Permissions.RatingReport],
  },
  {
    path: privatePaths.reportsNersSla,
    component: NercSlaReport,
    permission: [Permissions.ReadNercSlaReport],
  },
  {
    path: privatePaths.reportsNersTopic,
    component: NercTopicsReport,
    permission: [Permissions.ReadNercTopicsReport],
  },
  {
    path: privatePaths.reportsAdvancedTopic,
    component: ExtendedApplicationsReports,
    permission: [Permissions.ReadExtendedReports],
  },
  {
    path: privatePaths.reportsAdvancedSla,
    component: ReportsAdvancedSla,
    permission: [Permissions.ReadConsumerEvaluationReports],
  },
  {
    path: privatePaths.reportsSla,
    component: ReportsSla,
    permission: [Permissions.ReadSlaReports],
  },
  {
    path: privatePaths.reportsOperatorWorks,
    component: ReportsOperatorWork,
    permission: [Permissions.ReadOperatorWorkReports],
  },
  {
    path: privatePaths.reportsQualityOfSupply,
    component: ReportsSupplyQuality,
    permission: [Permissions.ReadQualitySupplyServices],
  },
  {
    path: privatePaths.reportsOperatorsToe,
    component: ToeOperatorsReports,
    permission: [Permissions.ReadToeOperatorReports],
  },
  {
    path: privatePaths.settings,
    component: SettingsGeneral,
    permission: [Permissions.ReadGeneralSetting],
  },
  {
    path: privatePaths.roles,
    component: Roles,
    permission: [Permissions.ReadRoles],
  },
  {
    path: privatePaths.topics,
    component: Topics,
    permission: [Permissions.ReadTopics],
  },
  {
    path: privatePaths.sounds,
    component: Sounds,
    permission: [Permissions.ReadSounds],
  },
  {
    path: privatePaths.routes,
    component: Routes,
    permission: [Permissions.ReadRoutes],
  },
  {
    path: privatePaths.rules,
    component: Rules,
    permission: [Permissions.ReadRules],
  },
  {
    path: privatePaths.reportsAppeals,
    component: Appeals,
    permission: [Permissions.ReadAppeals],
  },
  {
    path: privatePaths.mails,
    component: ReportsMails,
    permission: [Permissions.WatchMailAppeal],
  },
  {
    path: privatePaths.workingTime,
    component: SettingsWorkingTime,
    permission: [Permissions.ReadSchedule],
  },
  {
    path: privatePaths.users,
    component: Users,
    permission: [Permissions.ReadUsers],
  },
  {
    path: privatePaths.sipTrunks,
    component: SipTrunks,
    permission: [Permissions.ReadTrunks],
  },
  {
    path: privatePaths.profile,
    component: Profile,
  },
  {
    path: privatePaths.testing,
    component: Testing,
    permission: [Permissions.Testing],
    exact: true,
  },
  {
    path: privatePaths.blacklist,
    component: BlackList,
    permission: [Permissions.ReadBlacklist],
  },
  {
    path: privatePaths.handbookExternalPhoneNumbers,
    component: ExternalPhoneNumbers,
  },
  {
    path: privatePaths.handbooksClients,
    component: ClientsPage,
    permission: [Permissions.ReadClients],
  },
  {
    path: privatePaths.mailSender,
    component: MailSender,
    permission: [Permissions.ReadMailSenderConfigs],
  },
  {
    path: privatePaths.dispatcherModuleTable,
    component: DispatcherTable,
  },
  {
    path: privatePaths.module,
    component: Module,
  },
  {
    path: '*',
    component: Page404,
  },
];

export const devRouters: PrivateRoute[] = [
  {
    path: privatePaths.testingPhone,
    component: TestPhone,
  },
  {
    path: privatePaths.testingQueue,
    component: TestQueue,
  },
];

export default privateRoutes;
