const privatePath = {
  testing: '/testing',
  testingPhone: '/testing/phone',
  testingQueue: '/testing/queue',
  call: '/calls/:id',
  callsDashboard: '/calls/dashboard',
  reportsNersSla: '/reports/nerc-sla',
  reportsNersTopic: '/reports/nerc-topics',
  reportsHistory: '/reports/history',
  reportsHistoryAuto: '/reports/history-auto',
  reportsRating: '/reports/rating',
  reportsAppeals: '/reports/appeals',
  reportsOperatorsToe: '/reports/operator-toe',
  reportsAdvancedSla: '/reports/advanced-sla',
  reportsAdvancedTopic: '/reports/advanced-topic',
  reportsQualityOfSupply: '/reports/supply-quality',
  reportsSla: '/reports/sla',
  reportsOperatorWorks: '/reports/operator-work',
  protectedReports: '/protected-reports',
  protectedReportsId: '/protected-reports/:id',
  newApplication: '/application/new',
  application: '/applications/:id',
  appeals: '/reports/appeals',
  mails: '/reports/mails',
  settings: '/settings',
  roles: '/roles',
  topics: '/topics',
  sounds: '/sounds',
  routes: '/routes',
  rules: '/rules',
  workingTime: '/working-time',
  blacklist: '/blacklist',
  users: '/users',
  sipTrunks: '/sip-trunks',
  profile: '/profile',
  reportAstorWorkService: '/reports/astor',
  handbookExternalPhoneNumbers: '/handbooks/external-phone-numbers',
  handbooksClients: '/handbooks/clients',
  mailSender: '/mail-sender',
  canban: '/project/canban',
  list: '/crm/list',
  chanelsChats: '/input-chanels/chats',
  videoChats: '/input-chanels/video-chats',
  socialNetworks: '/input-chanels/social-networks',
  inputChanelsCRM: '/input-chanels/ms',
  inputChanelsMarketplace: '/input-chanels/market-place',
  inputChanelsWebChats: '/input-chanels/web-chats',
  inputChanelsEmail: '/input-chanels/email',
  inputChanelsForms: '/input-chanels/chanels-forms',
  inputChanelsCRMSiteBilder: '/input-chanels/site-bilder',
  outputChanelsSms: '/output-chanels/sms',
  outputChanelsMessengers: '/output-chanels/messengers',
  outputChanelsCallback: '/output-chanels/callback',
  outputChanelsEmail: '/output-chanels/email',
  externalToolsDB: '/external-tools/db',
  externalToolsNPSAgents: '/external-tools/nps-agents',
  externalToolsVirtualAgents: '/external-tools/virtual-agents',
  externalToolsSuperAgents: '/external-tools/super-agents',
  externalToolsAdveristingOffices: '/external-tools/adveristing-offices',
  externalToolsAI: '/external-tools/artificial-inteligence',
  internalToolsTimeTracking: '/internal-tools/time-tracking',
  internalToolsDataBase: '/internal-tools/db',
  settingsInterface: '/demo-settings/interface',
  dispatcherModuleTable: '/dispatcher-module/table',
  textToSpeech: '/text-to-speech',
  module: '/module',
  uploadDB: '/upload-db',
  tasksReport: '/project/tasks-report',
  crmAgreements: '/crm/agreements',
  agreementForm: '/crm/agreement-form',
  scopeForm: '/crm/scope-form',
  crmClients: '/crm/consumers',
  consumerForm: '/crm/consumer-form',
  paymentForm: '/crm/payment-form',
  financesReport: '/finances/report',
};

export default Object.freeze(privatePath);
